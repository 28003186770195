import * as Sentry from '@sentry/react';
import * as amplitude from '@amplitude/analytics-browser';
import {QueryCache, QueryClient} from 'react-query';
import {toast} from 'react-toastify';

export const initializeServices = () => {
  const isLoggedIn = !!localStorage.getItem('user:token');
  const authDetails = JSON.parse(localStorage.getItem('state'))?.auth;
  const user = authDetails?.user;
  const rest = authDetails?.userDetails;
  const fullName = (user && `${user?.first_name} ${user?.last_name}`) || '-';
  const userId = (user && user?.id) || '-';
  const userEmail = (user && user?.email) || '-';
  const userCompany = rest?.company?.name || '-';

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        if (
          error.response &&
          error.response.status === 401 &&
          (error.response.data.error.name === 'JsonWebTokenError' ||
            error.response.data.error.message === 'jwt malformed')
        ) {
          localStorage.clear();
          window.location = '/signin';
          window.location.reload();
        }
        if (error.response && error.response.status === 500) {
          toast.error('Ops! Internal Server Error');
          return;
        }
        const meta = query?.meta ?? {};
        if ('preventGlobalError' in meta && meta.preventGlobalError) {
          console.log(error);
          return;
        }
        toast.error(error.response?.data?.error);
      },
    }),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 2000,
      },
    },
  });

  if (typeof window !== 'undefined') {
    /////////////// Initialize the Sentry agent ///////////////////
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      tracesSampleRate:
        process.env.REACT_APP_APP_ENV_MODE === 'production' ? 0.1 : 0.6,
      environment: process.env.REACT_APP_APP_ENV_MODE,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        new Sentry.BrowserTracing({tracingOrigins: ['*']}),
        new Sentry.Replay({
          beforeAddRecordingEvent: event => {
            if (!isLoggedIn && !user) {
              return null;
            }
            if (user) {
              event.user = {
                id: userId,
                email: userEmail,
                username: fullName,
              };
            }
            return event;
          },
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      ignoreErrors: [
        // Ignore specific error messages
        'ChunkLoadError',
        'TypeError',
        'Loading chunk failed',
        'Network Error',
        // Regex patterns
        /failed to fetch/i,
        /\$\$pdfmake\$\$1/i,
        /Failed to load Stripe.js/i,
        /loading chunk \d+ failed/i,
      ],
    });

    Sentry.setUser({
      id: userId,
      username: fullName,
      email: userEmail,
      company: userCompany,
    });

    ////////////////// Initialize the Amplitude /////////////////////////
    if (user) {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, userEmail);
      amplitude.setUserId(userEmail);
      const identify = new amplitude.Identify();
      for (const [key, value] of Object.entries({
        user_id: userId,
        username: fullName,
        email: userEmail,
        company: userCompany,
      })) {
        identify.set(key, value);
      }
      amplitude.identify(identify);
    }
  }

  return {queryClient};
};
