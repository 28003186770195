import React from 'react';
// import {useQuery} from 'react-query';
import Layout from '../Layout';
import {Accordion, EmptyState} from '../../../components';
import {corruptedFile} from '../../../assets/images/images';
// import {fetchBusinessAml} from '../../../requests/queries/business360';
// import {useParams} from 'react-router-dom';

export default function AmlSearch() {
  // const {slug} = useParams();

  // const {
  //   data: businessAmlDetails,
  //   isLoading: businessAmlDetailsLoading,
  //   refetch: refetchAml,
  // } = useQuery(['business-aml-details', slug], () => fetchBusinessAml(slug));

  return (
    <Layout>
      <div className="space-y-4">
        <Accordion
          title="AML"
          border
          customClasses="rounded-xl"
          customChildrenClasses="mt-2 border-t border-grey-50"
          customPadding="px-6 py-5"
          noChildPadding
          customTitleSize="text-lg"
          customTitleColor="text-grey"
        >
          <div className="w-full pl-8 pr-10 pt-6 pb-6">
            <div className="bg-white80 w-full">
              <EmptyState
                body="You have not performed an AML check"
                src={corruptedFile}
                height={45}
                imageHeight={48}
                imageWidth={48}
                bodyMaxWidth="max-w-[179px]"
                buttonText="Perform AML Check"
              />
            </div>
          </div>
        </Accordion>
      </div>
    </Layout>
  );
}
