import React, {useState} from 'react';
import {
  checked,
  reconciliationAuthBg,
  reconciliationLogo,
  unchecked,
} from '../../../../assets/images/images';
import {PrimaryButton} from '../../../../components';
import {Constants, passwordCharacterCheck} from '../../../../helpers';
import {Link} from 'react-router-dom';

function SetPassword() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  return (
    <div
      className="w-full h-full bg-cover bg-center bg-no-repeat min-h-screen"
      style={{backgroundImage: `url(${reconciliationAuthBg})`}}
    >
      <div className="py-[70px] px-5 lg:px-0 flex flex-col items-center">
        <img
          src={reconciliationLogo}
          alt="Dojah logo"
          className="w-[192px] h-[37px] mb-6"
        />
        <div className="bg-white border m-auto border-batchBlue20 py-10 px-8 rounded-2xl w-full md:w-[536px] shadow-reconcilable">
          <div className="">
            <h2 className="text-deepBlue font-semibold text-md mb-[6px]">
              Set password
            </h2>
            <p className="text-sm text-body">
              Set up a password that gives you access to your account
            </p>

            <form className="mt-6">
              <label htmlFor="password" className="relative mt-4">
                Password
                <input
                  type={passwordShown ? 'text' : 'password'}
                  name="password"
                  id="password"
                  className="mt-2"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <span
                  role="button"
                  onClick={togglePassword}
                  className="absolute text-xs font-medium uppercase cursor-pointer text-grey40 right-3 bottom-4"
                >
                  {passwordShown ? 'hide password' : 'show password'}
                </span>
              </label>

              <p className="mt-4 text-black text-xs">Password requirement</p>
              <div className="mt-2 flex flex-wrap gap-2">
                {passwordCharacterCheck.map(({name, check}) => (
                  <div
                    key={name}
                    className="w-fit flex items-center gap-2 bg-white py-2 px-4 rounded-2xl"
                  >
                    <img
                      src={check(password) ? checked : unchecked}
                      alt=""
                      width={16}
                      height={16}
                    />
                    <span className="uppercase text-xs text-body font-medium mt-[3px]">
                      {name}
                    </span>
                  </div>
                ))}
              </div>

              <label htmlFor="confirm-password" className="relative mt-4">
                Confirm Password
                <input
                  type={confirmPasswordShown ? 'text' : 'password'}
                  name="confirm-password"
                  id="confirm-password"
                  className="mt-2"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
                <span
                  role="button"
                  onClick={toggleConfirmPassword}
                  className="absolute text-xs font-medium uppercase cursor-pointer text-grey40 right-3 bottom-4"
                >
                  {confirmPasswordShown ? 'hide password' : 'show password'}
                </span>
              </label>

              <PrimaryButton
                buttonText="Create Account"
                className="w-full mt-6"
              />
            </form>
          </div>
        </div>
        <div className="mt-8 flex items-center justify-center rounded-[30px] px-7 py-4 bg-white">
          <p className="text-sm font-medium text-body">
            Already have an account?{' '}
            <Link
              to={Constants.PAGES_URL.RECONCILIATION_SIGNIN}
              className="text-brandBlue font-semibold ml-1"
            >
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SetPassword;
