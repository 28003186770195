import React, {useMemo, useState} from 'react';
import ReconciliationLayout from '../ReconciliationLayout';
import {
  corruptedFile,
  projectsMobileNav,
} from '../../../../assets/images/images';
import {
  EmptyState,
  Loader,
  PageLimit,
  Pagination,
  SearchInput,
  TableLayout,
} from '../../../../components';

import {Constants} from '../../../../helpers';
import {useNavigate, useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {fetchCustomers} from '../../../../requests/queries/reconciliations';
import moment from 'moment';
import {useAccounts} from '../hooks/useAccounts';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';

function ProjectCustomers() {
  const [searchTerm, setSearchTerm] = useState('');
  const {slug} = useParams();
  const navigate = useNavigate();
  const {accountData, isLoading: accountsLoading} = useAccounts({slug});

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });
  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };

  const params = {
    project_id: slug,
    ...query,
    search: searchTerm,
  };
  const {data: customers, isLoading} = useQuery(
    ['customers', {...params}],
    () => fetchCustomers({params}),
  );

  const isEmpty = useMemo(
    () =>
      (!isLoading || !accountsLoading) &&
      accountData?.length === 0 &&
      customers?.rows?.length === 0,
    [accountData?.length, accountsLoading, customers?.rows?.length, isLoading],
  );
  return (
    <ReconciliationLayout
      pageTitle="Project A"
      pageIcon={projectsMobileNav}
      action={
        <>
          <SearchInput
            placeholder="Search for a customer..."
            onChange={value => {
              setQuery(prevState => ({
                ...prevState,
                page: 1,
              }));
              setSearchTerm(value);
            }}
            defaultValue={searchTerm}
          />
        </>
      }
    >
      {isLoading ? (
        <Loader height={35} />
      ) : isEmpty ? (
        <EmptyState
          noBtn
          src={corruptedFile}
          className="!h-[calc(80vh-200px)]"
          maxWidth="max-w-[289px]"
          body={`No customer(s) was found for this project`}
          // customBtn={
          //   component ? null : (
          //     <PrimaryButton
          //       buttonText="Add Transactions"
          //       className="py-[14px] px-[47px] rounded-lg mt-6"
          //       onClick={openUploadTransactionsModal}
          //     />
          //   )
          // }
        />
      ) : (
        <TableLayout className="w-full mt-6 font-inter">
          <thead className="text-small font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">full name</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">current balance</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">email</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">phone</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">last trans. date</span>
              </th>
            </tr>
          </thead>
          <tbody className="text-sm text-body bg-white">
            {customers &&
              customers?.rows.map(customer => (
                <tr
                  key={customer?.id}
                  onClick={() =>
                    navigate(
                      Constants.PAGES_URL.RECONCILIATION_CUSTOMER_DETAILS.replace(
                        ':slug',
                        customer?.id,
                      ),
                    )
                  }
                  className="text-left border-b cursor-pointer border-grey60"
                >
                  <td className="p-4 pl-12 whitespace-nowrap ">
                    {customer?.name || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap uppercase">
                    {shortenLargeNumbers(customer?.current_balance || 0, true, {
                      startAt: 'B',
                    }) || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {customer?.email || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {customer?.phone || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {moment(customer?.createdAt).format('DD MMM YYYY h:m a')}
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>
      )}
      {!isLoading && customers?.rows?.length > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-12 sm:gap-0 pl-12 pr-6">
          <PageLimit
            onLimitChange={limit => {
              setQuery(prevState => ({
                ...prevState,
                limit,
                page: 1,
              }));
            }}
            limit={query?.limit}
            total={customers?.page}
            length={customers?.rows?.length}
          />

          <Pagination
            total={customers?.pages}
            current={Number(customers?.page)}
            onPageChange={activePage => {
              pageClick(activePage);
              const searchParams = new URLSearchParams(
                document.location.search,
              );
              searchParams.set('page', activePage);
              // const newSearchParams = searchParams.toString();
              // navigate(`?${newSearchParams}`);
            }}
          />
        </div>
      )}
    </ReconciliationLayout>
  );
}

export default ProjectCustomers;
