import {useCallback, useMemo} from 'react';
import {useQuery} from 'react-query';

export const useBaasReceiptDownload = ({params, apiFunc}) => {
  const exportParams = useMemo(() => ({export: true}), []);

  const {refetch, isLoading: downloadLoading} = useQuery(
    ['export', {params: {...params, ...exportParams}}],
    () => apiFunc({params: {...params, ...exportParams}}),
    {
      onSuccess: data => {
        console.log(data);
        if (data?.entity?.length > 0) {
          window.open(data?.entity, '_blank')?.focus();
        }
      },

      enabled: false,
    },
  );

  const handleDownload = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return {handleDownload, downloadLoading};
};
