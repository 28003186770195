import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import Layout from '../Layout';
import {Accordion, EmptyState, Loader} from '../../../components';
import {corruptedFile, placeholder} from '../../../assets/images/images';
import {fetchLiveness} from '../../../requests/queries/customer360';
import SingleAnalysisModal from '../../DocumentAnalysis/SingleAnalysisModal';
import {ImageComponent} from '../../../components/ImageComponent';

export default function LivenessCheck() {
  const {slug} = useParams();

  const {data: livenessData, isLoading: livenessDataLoading} = useQuery(
    'liveness-data',
    () => fetchLiveness(slug),
    {
      enabled: !!slug,
    },
  );

  const LIVENESS = !livenessDataLoading && livenessData.data;

  const [openDocumentAnalysisModal, setOpenDAM] = useState(false);

  return (
    <Layout pageTitle="Liveness Check">
      <SingleAnalysisModal
        open={openDocumentAnalysisModal}
        setOpen={setOpenDAM}
        customer_id={slug}
      />
      {livenessDataLoading ? (
        <Loader />
      ) : !livenessDataLoading && LIVENESS?.length === 0 ? (
        <div className="bg-white80 w-full">
          <EmptyState
            body="There is no information to display here at the moment"
            src={corruptedFile}
            height={45}
            imageHeight={48}
            imageWidth={48}
            bodyMaxWidth="max-w-[179px]"
            buttonText="Validate document"
            onClick={() => {
              setOpenDAM(true);
            }}
            noBtn
          />
        </div>
      ) : (
        <div className="space-y-4">
          {!livenessDataLoading &&
            LIVENESS?.map(
              (liveness, index) =>
                (liveness?.id_url || liveness?.face_url) && (
                  <Accordion
                    key={index}
                    title="Liveness Check"
                    border
                    customClasses="rounded-xl"
                    customComponent={
                      <div className="flex items-center gap-2 rounded-full px-2 py-1 bg-grey60 text-grey text-xs font-medium uppercase">
                        <span className="-mb-[2px]">
                          {moment(liveness?.datetime).format(
                            'Do MMMM YYYY, h:mmA',
                          )}
                        </span>
                      </div>
                    }
                    customChildrenClasses="mt-2 border-t border-grey-50"
                    customPadding="px-6 py-5"
                    noChildPadding
                    customTitleSize="text-lg"
                    customTitleColor="text-grey"
                    isOpen={index >= 1}
                  >
                    <div className="pl-8 pr-10 pt-6 pb-6">
                      <div className="flex items-center gap-2">
                        <div className="flex items-center gap-2 rounded-full px-2 py-1 bg-blue-100 text-dojahBlue-900 text-xs font-medium uppercase w-fit">
                          <span className="-mb-[2px]">
                            {Math.ceil(liveness?.face_confidence || 0)}% image
                            match{' '}
                          </span>
                        </div>
                        <div className="flex items-center gap-2 rounded-full px-2 py-1 bg-accent-100 text-green-700 text-xs font-medium uppercase w-fit">
                          <span className="-mb-[2px]">
                            {liveness?.document_type || '-'}
                          </span>
                        </div>
                      </div>
                      <div className="mt-6 flex flex-col justify-center  gap-14 h-full">
                        <ImageComponent
                          imageUrl={liveness?.face_url || placeholder}
                        />
                        {liveness?.id_url && (
                          <ImageComponent
                            imageUrl={liveness?.id_url || placeholder}
                          />
                        )}
                      </div>
                    </div>
                  </Accordion>
                ),
            )}
        </div>
      )}
    </Layout>
  );
}
