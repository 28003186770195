import React, {useState} from 'react';
import {
  reconciliationAuthBg,
  reconciliationLogo,
} from '../../../../assets/images/images';
import {PrimaryButton} from '../../../../components';
import {addCompany} from '../../../../requests';
import {onSignupSuccessHelper} from '../../../../helpers';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

function GoogleSignup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [company, setCompany] = useState('');

  const {isLoading: sLoading, mutate} = useMutation(data => addCompany(data), {
    onSuccess: data => {
      onSignupSuccessHelper(data, dispatch, navigate);
      sessionStorage.removeItem('tempToken');
    },
    onError: error => {
      toast.error(error.response.data.error);
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    mutate({company});
  };

  return (
    <div
      className="w-full h-full bg-cover bg-center bg-no-repeat min-h-screen"
      style={{backgroundImage: `url(${reconciliationAuthBg})`}}
    >
      <div className="py-[70px] px-5 lg:px-0 flex flex-col items-center">
        <img
          src={reconciliationLogo}
          alt="Dojah logo"
          className="w-[192px] h-[37px] mb-6"
        />
        <div className="bg-white border m-auto border-batchBlue20 py-10 px-8 rounded-2xl w-full md:w-[536px] shadow-reconcilable">
          <div className="">
            <h2 className="text-deepBlue font-semibold text-md mb-[6px]">
              Sign up with google
            </h2>
            <p className="text-sm text-body">
              Create a new account by signing up with google
            </p>

            <form className="mt-6" onSubmit={handleSubmit}>
              <label htmlFor="companyName" className="block mt-4">
                Company name
                <input
                  type="text"
                  name="companyName"
                  id="companyName"
                  className="mt-2"
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                  required
                />
              </label>

              <PrimaryButton
                buttonText="Continue"
                className="w-full mt-6"
                type="submit"
                disabled={sLoading}
                loading={sLoading}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoogleSignup;
