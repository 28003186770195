import {toast} from 'react-toastify';

const settlementType = [
  {label: 'Wallet', value: 'wallet'},
  // {label: 'Bank', value: 'bank'},
];

const typeAndStatusFilterOptions = [
  {
    title: 'Status',
    name: 'transaction_status',
    values: [
      {value: 'completed', label: 'Successful', name: 'status'},
      {value: 'processing', label: 'Processing', name: 'status'},
      {value: 'pending', label: 'Pending', name: 'status'},
      {value: 'failed', label: 'Failed', name: 'status'},
    ],
  },
  {
    title: 'Type',
    name: 'transaction_type',
    values: [
      {value: 'deposit', label: 'Credit', name: 'type'},
      {value: 'transfer', label: 'Debit', name: 'type'},
    ],
  },
];
const copyText = text => {
  navigator.clipboard.writeText(text);
  toast.success(`${text?.toUpperCase()} copied!`);
};

export {settlementType, typeAndStatusFilterOptions, copyText};
