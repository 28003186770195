import React, {useContext, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Accordion, Loader} from '../../../../components';
import * as Actions from '../../../../store/actions';
import Layout from './Layout';
import {PageContext} from '../../../../helpers/context';
import {useQuery} from 'react-query';
import {fetchCase} from '../../../../requests/queries/flows';
import {useCasesDetails} from '../hooks/useCasesDetails';

function CasesDetails() {
  const {slug} = useParams();

  const {data: caseData, isLoading} = useQuery(['a_case_details', slug], () =>
    fetchCase(slug),
  );

  const caseDetails = useMemo(
    () => !isLoading && caseData && caseData?.entity,
    [caseData, isLoading],
  );

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  const {ALL_DATA} = useCasesDetails({caseDetails});

  return (
    <Layout>
      <div className=" xl:ml-4 xl:basis-[43.91%] mt-6 xl:mt-0 px-4 grow xl:grow-0 xl:p-8 pt-4 xl:pt-[36px] bg-white80 xl:bg-white">
        {isLoading ? (
          <Loader height={40} />
        ) : (
          <div className="flex flex-col gap-3">
            {ALL_DATA?.map(({title, data}, index) => (
              <Accordion
                key={title}
                customTitle={<h2 className="font-medium w-full ">{title}</h2>}
                noChildPadding
                customPadding=""
                isOpen={index > 1}
                customClasses="bg-white80 p-3 pb-3"
                background="bg-white4"
              >
                <div className="flex flex-col mt-6">{data}</div>
              </Accordion>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
}

export default connect(state => state, Actions)(CasesDetails);
