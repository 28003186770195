import {PAGES_URL} from '../../helpers/constants';

const IGNORE_KEYS = [
  'date_created',
  'app_id',
  'entity_type',
  'classification_id',
  'bulk_ref',
  'country',
  'affiliates',
  'id',
  'business',
];

const ROUTES = [
  {
    name: 'Business Lookup',
    path: PAGES_URL.BUSINESS_360_BUSINESS_LOOKUP,
  },
  {
    name: 'Business ID',
    path: PAGES_URL.BUSINESS_360_BUSINESS_ID,
  },
  {
    name: 'Aml Search',
    path: PAGES_URL.BUSINESS_360_AML_SEARCH,
  },
  // {
  //   name: "Director's Info",
  //   path: PAGES_URL.BUSINESS_360_DIRECTOR_INFO,
  // },
];

const TIMELINE = [
  {
    datetime: '2021-08-12 12:00:00+00:00',
    event_value: 'Verification Started',
  },
  {
    datetime: '2021-08-12 12:00:00+00:00',
    event_value: 'Index Step Completed',
  },
  {
    datetime: '2021-08-12 12:00:00+00:00',
    event_value: 'Countries Step Completed',
  },
  {
    datetime: '2021-08-12 12:00:00+00:00',
    event_value: 'User Data Collected',
  },
  {
    datetime: '2021-08-12 12:00:00+00:00',
    event_value: 'User Data Collected',
  },
];

const getInitials = (name, count) =>
  (name || '')
    ?.split(' ')
    ?.map(word => word.charAt(0).toUpperCase())
    ?.join('')
    .substring(0, count);

export {IGNORE_KEYS, ROUTES, TIMELINE, getInitials};
