import React from 'react';
import {
  mailBlue,
  reconciliationAuthBg,
  reconciliationLogo,
} from '../../../../assets/images/images';

function OnboardingRedirect() {
  return (
    <div
      className="w-full h-full bg-cover bg-center bg-no-repeat min-h-screen"
      style={{backgroundImage: `url(${reconciliationAuthBg})`}}
    >
      <div className="py-[70px] px-5 lg:px-0 flex flex-col items-center">
        <img
          src={reconciliationLogo}
          alt="Dojah logo"
          className="w-[192px] h-[37px] mb-6"
        />
        <div className="bg-white flex items-center justify-center flex-col border m-auto border-batchBlue20 py-10 px-8 rounded-2xl w-full md:w-[536px] shadow-reconcilable">
          <img src={mailBlue} alt="mail" />
          <h2 className="text-deepBlue font-semibold text-md mt-8 text-center">
            Thank you for creating an account
          </h2>
          <p className="text-sm text-body mt-1 text-center">
            Your reconcilo account has been created successfully, kindly go to
            your email to continue with your onboarding process{' '}
          </p>
        </div>
      </div>
    </div>
  );
}

export default OnboardingRedirect;
