import {api} from '../../../helpers';

function addWebhookSubscription({app_id, ...rest}) {
  return api
    .post(`/users/webhook-subscriptions?app_id=${app_id}`, {app_id, ...rest})
    .then(res => res.data);
}
function deleteWebhookSubscription({app_id, service}) {
  return api
    .delete(`/users/webhook-subscriptions?app_id=${app_id}&service=${service}`)
    .then(res => res.data);
}

function fetchWebhookSubscriptions(params) {
  return api
    .get('/users/webhook-subscriptions', {...params})
    .then(res => res.data);
}

export {
  addWebhookSubscription,
  fetchWebhookSubscriptions,
  deleteWebhookSubscription,
};
