import {api} from '../../helpers';
import {getCookieValue} from '../../helpers/getCookie';

const env = getCookieValue('env');
const environment = env === 'Production' ? 'live' : env;

const baasBaseRoute = '/baas/wallet';
api.interceptors.request.use(
  config => {
    const isBAASRequest = config?.url?.startsWith(baasBaseRoute);
    const environmentParam = {
      environment: environment?.toLowerCase() || 'sandbox',
    };

    if (isBAASRequest) {
      if (['get', 'delete'].includes(config.method?.toLowerCase())) {
        config.params = {
          ...config.params,
          ...environmentParam,
        };
      } else if (
        ['post', 'put', 'patch'].includes(config.method?.toLowerCase())
      ) {
        if (typeof config.data === 'string') {
          config.data = JSON.parse(config.data);
        }
        config.data = {
          ...config.data,
          ...environmentParam,
        };
        if (typeof config.data === 'string') {
          config.data = JSON.stringify(config.data);
        }
      }
    }

    return config;
  },
  error => Promise.reject(error),
);

function fetchBAASTransactions(params) {
  return api
    .get(`${baasBaseRoute}/pool_account_transactions/fetch`, {...params})
    .then(res => res.data);
}
function fetchBAASBreakdown(params) {
  return api
    .get(`${baasBaseRoute}/admin/transaction_breakdown`, {...params})
    .then(res => res.data);
}
function fetchBAASWalletSettlement(params) {
  return api
    .get(`${baasBaseRoute}/settlement`, {...params})
    .then(res => res.data);
}
function fetchBAASCompanyWallets(params) {
  return api
    .get(`${baasBaseRoute}/fetch_company_wallets`, {...params})
    .then(res => res.data);
}
function fetchBAASCompanyWallet(params) {
  return api
    .get(`${baasBaseRoute}/retrieve`, {...params})
    .then(res => res.data);
}
function fetchBAASTransaction(params) {
  return api
    .get(`${baasBaseRoute}/transaction/retrieve`, {...params})
    .then(res => res.data);
}
function fetchBAASTransactionAnalysis(params) {
  return api
    .get(`${baasBaseRoute}/transaction_analysis`, {...params})
    .then(res => res.data);
}
function fetchBAASTransactionPayouts(params) {
  return api
    .get(`${baasBaseRoute}/payout/fetch`, {...params})
    .then(res => res.data);
}
function fetchBAASTransactionPayout(params) {
  return api.get(`${baasBaseRoute}/payout`, {...params}).then(res => res.data);
}
function fetchBAASChart(chartsParams) {
  return api
    .get(`${baasBaseRoute}/chart`, {params: {...chartsParams}})
    .then(res => res.data);
}
function fetchBAASSettings(params) {
  return api
    .get(`${baasBaseRoute}/settings`, {...params})
    .then(res => res.data);
}
function createBAASSettings(data) {
  return api.post(`${baasBaseRoute}/settings`, {...data}).then(res => res.data);
}
function updateEmailNotification(data) {
  return api
    .post(`${baasBaseRoute}/email_notification`, {...data})
    .then(res => res.data);
}
function createBAASPin(data) {
  return api.post(`${baasBaseRoute}/pin`, {...data}).then(res => res.data);
}
function updateBAASPin(data) {
  return api.put(`${baasBaseRoute}/pin`, {...data}).then(res => res.data);
}
function validateBAASPin(data) {
  return api
    .post(`${baasBaseRoute}/pin/validate`, {...data})
    .then(res => res.data);
}

function updateBAASSettings(data) {
  return api.put(`${baasBaseRoute}/settings`, {...data}).then(res => res.data);
}
////////////////////////////////////// TRANSFER //////////////////////////////////////////
function initiateBAASTransfer(data) {
  return api
    .post(`${baasBaseRoute}/withdrawal/initiate`, {...data})
    .then(res => res.data);
}
function completeBAASTransfer(data) {
  return api
    .post(`${baasBaseRoute}/withdrawal/complete`, {...data})
    .then(res => res.data);
}
function sendBAASOtp(data) {
  return api
    .post(`${baasBaseRoute}/withdrawal/send_otp`, {...data})
    .then(res => res.data);
}
function validateBAASOtp(data) {
  return api
    .post(`${baasBaseRoute}/withdrawal/validate_otp`, {...data})
    .then(res => res.data);
}
function accountRetrieval(params) {
  return api
    .get(`/baas/identity/resolve_nuban`, {...params})
    .then(res => res.data);
}

export {
  accountRetrieval,
  createBAASPin,
  updateBAASPin,
  fetchBAASChart,
  validateBAASPin,
  fetchBAASSettings,
  fetchBAASBreakdown,
  updateBAASSettings,
  createBAASSettings,
  fetchBAASTransaction,
  fetchBAASTransactions,
  fetchBAASCompanyWallet,
  updateEmailNotification,
  fetchBAASCompanyWallets,
  fetchBAASWalletSettlement,
  fetchBAASTransactionPayout,
  fetchBAASTransactionPayouts,
  fetchBAASTransactionAnalysis,
  initiateBAASTransfer,
  completeBAASTransfer,
  sendBAASOtp,
  validateBAASOtp,
};
