import React, {useCallback, useState, memo} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {
  useMutation,
  // useQuery
} from 'react-query';
import {
  // google,
  reconciliationAuthBg,
  reconciliationLogo,
} from '../../../../assets/images/images';
import {
  Checkbox,
  // ButtonLoader,
  PrimaryButton,
} from '../../../../components';
import {Constants} from '../../../../helpers';
import {signupUser} from '../../../../requests/queries/reconciloAuth';
import {toast} from 'react-toastify';
// import {googleSignin} from '../../../../requests';

const initialInputs = {
  first_name: '',
  last_name: '',
  company: '',
  email: '',
};

const Signup = memo(() => {
  const [inputs, setInputs] = useState(initialInputs);
  const [isAgreed, setIsAgreed] = useState(false);
  // const [googleLoading, setGoogleLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputs = useCallback(e => {
    const {name, value} = e.target;
    setInputs(prev => ({...prev, [name]: value}));
  }, []);

  const {mutateAsync, isLoading} = useMutation(
    'create-app',
    data => signupUser(data),
    {
      onSuccess: data => {
        toast.success(data?.message);
        setInputs(initialInputs);
        navigate('/signin');
      },
      onError: error => {
        toast.error(error.response.data?.error);
      },
    },
  );

  // const {refetch: googleSigninRefetch} = useQuery(
  //   'google-signin',
  //   () => googleSignin(),
  //   {
  //     enabled: false,
  //     onSuccess: data => {
  //       setGoogleLoading(false);
  //       const {url} = data;
  //       if (url) {
  //         window.location.href = url;
  //       }
  //     },
  //     onError: error => {
  //       setGoogleLoading(false);
  //       toast.error(error.message || 'Google sign-in failed');
  //     },
  //   },
  // );

  // const handleGoogleSignUp = () => {
  //   setGoogleLoading(true);
  //   googleSigninRefetch();
  // };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const data = {
        ...inputs,
      };

      await mutateAsync(data);
    } catch (e) {
      console.log(e);
    }
  };

  const isSubmitDisabled =
    Object.values(inputs).some(value => !value) || !isAgreed;

  return (
    <div
      className="w-full h-full bg-cover bg-center bg-no-repeat min-h-screen"
      style={{backgroundImage: `url(${reconciliationAuthBg})`}}
    >
      <div className="py-[70px] px-5 lg:px-0 flex flex-col items-center">
        <img
          src={reconciliationLogo}
          alt="Dojah logo"
          className="w-[192px] h-[37px] mb-6"
        />
        <div className="bg-white border m-auto border-batchBlue20 py-10 px-8 rounded-2xl w-full md:w-[536px] shadow-reconcilable">
          <div className="">
            <h2 className="text-deepBlue font-semibold text-md mb-[6px]">
              Create Account
            </h2>
            <p className="text-sm text-body">
              Provide the information below to create an account
            </p>

            <div className="flex flex-col md:flex-row items-center gap-4 mt-6">
              <div className="flex-1 w-full">
                <label htmlFor="first_name" className="block">
                  First name
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    className="mt-2 block w-full"
                    value={inputs?.first_name}
                    onChange={handleInputs}
                  />
                </label>
              </div>
              <div className="flex-1 w-full">
                <label htmlFor="last_name" className="block">
                  Last name
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    className="mt-2 block w-full"
                    value={inputs?.last_name}
                    onChange={handleInputs}
                  />
                </label>
              </div>
            </div>

            <label htmlFor="company" className="block mt-4">
              Company name
              <input
                type="text"
                name="company"
                id="company"
                className="mt-2"
                value={inputs?.company}
                onChange={handleInputs}
              />
            </label>
            <label htmlFor="email" className="block mt-4">
              Email address
              <input
                type="email"
                name="email"
                id="email"
                className="mt-2"
                value={inputs?.email}
                onChange={handleInputs}
              />
            </label>

            {/*<button*/}
            {/*  type="button"*/}
            {/*  disabled={googleLoading}*/}
            {/*  className="flex items-center justify-center w-full h-12 gap-2 p-4 mt-4 border rounded md:mt-6 border-body"*/}
            {/*  onClick={handleGoogleSignUp}*/}
            {/*>*/}
            {/*  {!googleLoading && (*/}
            {/*    <img src={google} alt="" width={20} height={20} />*/}
            {/*  )}*/}
            {/*  {googleLoading ? (*/}
            {/*    <ButtonLoader color="black" />*/}
            {/*  ) : (*/}
            {/*    <span className="self-start text-sm font-medium text-grey">*/}
            {/*      Sign up with Google*/}
            {/*    </span>*/}
            {/*  )}*/}
            {/*</button>*/}

            <div className="flex items-center mt-6">
              <Checkbox
                label={
                  <>
                    I agree to Reconcilo by Dojah&apos;s{' '}
                    <span className="text-brandBlue">
                      <a
                        href="https://dojah.io/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        privacy policy, data processing
                      </a>
                    </span>{' '}
                    and{' '}
                    <span className="text-brandBlue">
                      <a
                        href="https://dojah.io/terms-of-service"
                        target="_blank"
                        rel="noreferrer"
                      >
                        terms of use
                      </a>
                    </span>{' '}
                    by clicking &quot;Continue&quot;.
                  </>
                }
                className="mb-2 !pl-0"
                labelStyle="text-sm text-body"
                align="items-start"
                isChecked={isAgreed}
                onChange={e => setIsAgreed(e.target.checked)}
              />
            </div>

            <PrimaryButton
              type="submit"
              buttonText="Continue"
              className="w-full mt-6"
              disabled={isSubmitDisabled}
              loading={isLoading}
              onClick={handleSubmit}
            />
          </div>
        </div>

        <div className="mt-8 flex items-center justify-center rounded-[30px] px-7 py-4 bg-white">
          <p className="text-sm font-medium text-body">
            Already have an account?{' '}
            <Link
              to={Constants.PAGES_URL.RECONCILIATION_SIGNIN}
              className="text-brandBlue font-semibold ml-1"
            >
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
});

export default Signup;
