import {api} from '../../helpers';

function fetchBusinessPool(appIds, params) {
  return api
    .get(`/utils/api/businesses${appIds && `?app_ids=${appIds}`}`, {
      params,
    })
    .then(res => res.data);
}
function fetchBusinessEntity({customer_id, entity_type}) {
  return api
    .get('/utils/api/customer_data', {
      params: {
        customer_id,
        entity_type,
      },
    })
    .then(res => res.data);
}
function fetchBusinessAml(business_id) {
  return api
    .get(`/users/customer-pool/${business_id}/aml`)
    .then(res => res.data);
}
function fetchBusinessID(business_id) {
  return api
    .get(`/users/customer-pool/${business_id}/business_id`)
    .then(res => res.data);
}
function addComment(business_id, data) {
  return api
    .post(`/users/customer-pool/${business_id}/comments`, {
      ...data,
      type: 'business',
    })
    .then(res => res.data);
}
function fetchComments(business_id) {
  return api
    .get(`/users/customer-pool/${business_id}/comments`)
    .then(res => res.data);
}

function fetchBusinessDetails(businessId) {
  return api.get(`/utils/api/business/${businessId}`).then(res => res.data);
}
function deleteComment(business_id, commentId) {
  return api
    .delete(`/users/customer-pool/${business_id}/comments/${commentId}`)
    .then(res => res.data);
}

export {
  fetchBusinessPool,
  addComment,
  fetchComments,
  deleteComment,
  fetchBusinessDetails,
  fetchBusinessEntity,
  fetchBusinessID,
  fetchBusinessAml,
};
