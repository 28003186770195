import React, {memo} from 'react';
import {NavLink} from 'react-router-dom';
import {
  hyperLinkWhite,
  quickActionsNav,
  developersNav,
  configurationNav,
  apiLogsNav,
  documentationNav,
  easyOnboardNav,
  verificationsNav,
  profileNav,
  teamNav,
  auditLogsNav,
  billingNav,
  settingsNav,
  quickActionsMobileNav,
  developersMobileNav,
  configurationMobileNav,
  apiLogsMobileNav,
  documentationMobileNav,
  hyperLinkGrey,
  easyOnboardMobileNav,
  verificationsMobileNav,
  amlScreeningNav,
  amlScreeningMobileNav,
  overviewNav,
  overviewMobileNav,
  webhookMobileNav,
  webhookNav,
  pendingCasesNav,
  pendingCasesMobileNav,
  addressVerificationMobileNav,
  addressVerificationNav,
  easyCreditCheckMobileNav,
  easyCreditCheckNav,
  documentAnalysisNav,
  documentAnalysisMobileNav,
  messagingReportNav,
  messagingReportMobileNav,
  customerMobileNav,
  customerNav,
  customerIndividualNav,
  customerIndividualMobileNav,
  customerBusinessNav,
  customerBusinessMobileNav,
  fraudFlowMobileNav,
  fraudFlowNav,
  customListsNav,
  customListsMobileNav,
  transactionHistoryNav,
  transactionHistoryMobileNav,
  billingMobileNav,
  // apiReportNav,
  // apiReportMobileNav,
  settingsMobileNav,
  govDataNav,
  govDataMobileNav,
  flowsNav,
  flowsMobileNav,
  easyDetectMobileNav,
  easyDetectNav,
  verificationAnalysis,
  baasOverview,
  baasTransactions,
  baasWallets,
  baasPayouts,
  baasSettings,
  reconciliationNav,
  reconciliationMobileNav,
  baasIconWhite,
  baasIconBlack,
} from '../../assets/images/images';

import {Constants} from '../../helpers';
import {usePermissions} from '../../hooks/useSidbarPermissions';
import {NavigationItem, SubNavigationDropdown} from './components';
import {reconciloNavLinks} from './constants';

const MainSideNavigations = memo(
  ({media, splitLocation, pathname, openDropdown, toggleSubRoute}) => {
    const {
      USER_PERMISSIONS,
      permittedLinks,
      canViewIdVerification,
      canManageFlows,
      manageDetectFlows,
      reviewCases,
      canReviewCases,
      canViewHistory,
      canViewApiCalls,
      managePermissions,
      manageAuditLogs,
      canViewApiLogs,
      canManageTokens,
      webhookPermission,
      canViewReconciliation,
      projectId,
      baasPagePermissions: {
        canViewOverview,
        canViewPayouts,
        canViewTransactions,
        canViewWallets,
        canManageSettings,
      },
      modularisation: {
        baas,
        customer_pool,
        address_verification_services,
        aml_screening_services,
        api_report_services,
        audit_logs_services,
        api_logs_services,
        billings_services,
        credit_check_services,
        developers_services,
        easy_detect_report_services,
        easy_lookup_services,
        easy_onboard_services,
        id_verification_services,
        profile_services,
        quick_actions_services,
        teams_services,
        custom_link,
        reconciliation,
        messaging_report_services,
        document_analysis,
        easy_detect_services,
        is_partner_company,
      },
    } = usePermissions();

    return (
      <>
        <div className="flex flex-col gap-2">
          {quick_actions_services && (
            <NavigationItem
              media={media}
              title="Quick Actions"
              active={splitLocation[1] === ''}
              to={Constants.PAGES_URL.QUICKACTIONS}
              webIcon={quickActionsNav}
              mobileIcon={quickActionsMobileNav}
            />
          )}

          {customer_pool && (
            <div className="flex flex-col gap-2 rounded lg:text-white text-grey">
              <SubNavigationDropdown
                media={media}
                toggleSubRoute={() => toggleSubRoute('customer')}
                src={media ? customerNav : customerMobileNav}
                open={openDropdown === 'customer'}
                label="Customers"
              />

              {openDropdown === 'customer' && (
                <div className="flex flex-col">
                  <NavigationItem
                    media={media}
                    title="Individual"
                    active={splitLocation[1] === ''}
                    to={Constants.PAGES_URL.CUSTOMER_360}
                    webIcon={customerIndividualNav}
                    mobileIcon={customerIndividualMobileNav}
                    inner
                  />
                  <NavigationItem
                    media={media}
                    title=" Business"
                    active={splitLocation[1] === ''}
                    to={Constants.PAGES_URL.BUSINESS_360}
                    webIcon={customerBusinessNav}
                    mobileIcon={customerBusinessMobileNav}
                    inner
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col">
          {(permittedLinks(USER_PERMISSIONS?.lookupPermissions) ||
            permittedLinks(USER_PERMISSIONS?.onboardPermissions) ||
            permittedLinks(USER_PERMISSIONS?.documentAnalysisPermissions) ||
            permittedLinks(USER_PERMISSIONS?.addressVerificationPermissions)) &&
            (address_verification_services ||
              document_analysis ||
              easy_lookup_services ||
              easy_onboard_services) && (
              <p className="text-xs text-[#73739B] pt-6 pb-2 pl-4 uppercase">
                identity verification
              </p>
            )}

          {
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-2 rounded lg:text-white text-grey">
                <div className="flex flex-col gap-2">
                  {easy_lookup_services &&
                    permittedLinks(USER_PERMISSIONS?.lookupPermissions) && (
                      <NavigationItem
                        media={media}
                        title="Government Lookup"
                        active={splitLocation[1] === ''}
                        to={Constants.PAGES_URL.INDIVIDUAL_LOOKUP}
                        webIcon={govDataNav}
                        mobileIcon={govDataMobileNav}
                      />
                    )}

                  {easy_onboard_services &&
                    permittedLinks(USER_PERMISSIONS?.onboardPermissions) && (
                      <div className="flex flex-col gap-2 rounded lg:text-white text-grey">
                        <SubNavigationDropdown
                          media={media}
                          toggleSubRoute={() => toggleSubRoute('easy-onboard')}
                          src={media ? easyOnboardNav : easyOnboardMobileNav}
                          open={openDropdown === 'easy-onboard'}
                          label="EasyOnboard"
                        />
                      </div>
                    )}

                  {openDropdown === 'easy-onboard' && (
                    <div className="flex flex-col">
                      {permittedLinks(USER_PERMISSIONS?.onboardPermissions) &&
                        easy_onboard_services && (
                          <NavigationItem
                            media={media}
                            title="Verification flow"
                            active={splitLocation[1] === ''}
                            to={Constants.PAGES_URL.EASY_ONBOARD}
                            webIcon={flowsNav}
                            mobileIcon={flowsMobileNav}
                            inner
                          />
                        )}

                      {canViewIdVerification &&
                      (id_verification_services || easy_onboard_services) ? (
                        <NavigationItem
                          media={media}
                          title="Verifications"
                          active={splitLocation[1] === ''}
                          to={Constants.PAGES_URL.VERIFICATIONS}
                          webIcon={verificationsNav}
                          mobileIcon={verificationsMobileNav}
                          inner
                        />
                      ) : null}

                      {canViewIdVerification &&
                      (id_verification_services || easy_onboard_services) ? (
                        <NavigationItem
                          media={media}
                          title="Verifications Analytics"
                          active={splitLocation[1] === ''}
                          to={Constants.PAGES_URL.VERIFICATION_ANALYTICS}
                          webIcon={verificationAnalysis}
                          mobileIcon={verificationsMobileNav}
                          inner
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              </div>

              {document_analysis &&
                permittedLinks(
                  USER_PERMISSIONS?.documentAnalysisPermissions,
                ) && (
                  <NavigationItem
                    media={media}
                    title="Document Analysis"
                    active={splitLocation[1] === ''}
                    to={Constants.PAGES_URL.DOCUMENT_ANALYSIS}
                    webIcon={documentAnalysisNav}
                    mobileIcon={documentAnalysisMobileNav}
                  />
                )}

              {address_verification_services &&
                permittedLinks(
                  USER_PERMISSIONS?.addressVerificationPermissions,
                ) && (
                  <NavigationItem
                    media={media}
                    title="Address Verification"
                    active={splitLocation[1] === ''}
                    to={Constants.PAGES_URL.ADDRESS_VERIFICATION}
                    webIcon={addressVerificationNav}
                    mobileIcon={addressVerificationMobileNav}
                  />
                )}
            </div>
          }
        </div>

        {(aml_screening_services ||
          reconciliation ||
          easy_detect_services ||
          custom_link ||
          credit_check_services) && (
          <p className="text-xs text-[#73739B] pt-6 pb-2 pl-4 uppercase">
            FRAUD MONITORING
          </p>
        )}
        <div className="flex flex-col">
          {permittedLinks(USER_PERMISSIONS?.amlScreeningPermissions) &&
            aml_screening_services && (
              <NavigationItem
                media={media}
                title="AML Screening"
                active={
                  pathname === Constants.PAGES_URL.AML_USER_SCREENING ||
                  pathname === Constants.PAGES_URL.AML_BUSINESS_SCREENING
                }
                to={Constants.PAGES_URL.AML_USER_SCREENING}
                webIcon={amlScreeningNav}
                mobileIcon={amlScreeningMobileNav}
              />
            )}

          {reconciliation && canViewReconciliation && (
            <div className="mt-2 flex flex-col gap-2 rounded lg:text-white text-grey">
              <SubNavigationDropdown
                media={media}
                toggleSubRoute={() => toggleSubRoute('reconciliation')}
                src={media ? reconciliationNav : reconciliationMobileNav}
                open={openDropdown === 'reconciliation'}
                label="Reconciliation"
              />

              {openDropdown === 'reconciliation' && (
                <div className="flex flex-col">
                  {reconciloNavLinks?.map((link, idx) => (
                    <NavigationItem
                      key={link?.label + idx}
                      media={media}
                      title={link?.label}
                      active={splitLocation[1] === ''}
                      to={link?.url.replace(':slug', projectId)}
                      webIcon={link?.imgSrc}
                      mobileIcon={link?.imgSrc}
                      inner
                    />
                  ))}
                </div>
              )}
            </div>
          )}

          {easy_detect_services && (reviewCases || manageDetectFlows) && (
            <div className="mt-2 flex flex-col gap-2 rounded lg:text-white text-grey">
              <SubNavigationDropdown
                media={media}
                toggleSubRoute={() => toggleSubRoute('easy-detect')}
                src={media ? overviewNav : overviewMobileNav}
                open={openDropdown === 'easy-detect'}
                label="EasyDetect"
              />

              {openDropdown === 'easy-detect' && (
                <div className="flex flex-col">
                  {/*{manageDetectFlows && (*/}
                  {/*  <NavigationItem*/}
                  {/*    media={media}*/}
                  {/*    title="Overview"*/}
                  {/*    active={splitLocation[1] === ''}*/}
                  {/*    to={Constants.PAGES_URL.OVERVIEW}*/}
                  {/*    webIcon={overviewNav}*/}
                  {/*    mobileIcon={overviewMobileNav}*/}
                  {/*    inner*/}
                  {/*  />*/}
                  {/*)}*/}

                  {canManageFlows && manageDetectFlows && (
                    <NavigationItem
                      media={media}
                      title="Fraud Flows"
                      active={splitLocation[1] === ''}
                      to={Constants.PAGES_URL.FLOWS}
                      webIcon={fraudFlowNav}
                      mobileIcon={fraudFlowMobileNav}
                      inner
                    />
                  )}
                  {canReviewCases && reviewCases && (
                    <NavigationItem
                      media={media}
                      title="Pending Cases"
                      active={splitLocation[1] === ''}
                      to={Constants.PAGES_URL.OVERVIEW_PENDING_CASES}
                      webIcon={pendingCasesNav}
                      mobileIcon={pendingCasesMobileNav}
                      inner
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="mt-2 flex flex-col gap-2">
          {custom_link && (
            <NavigationItem
              media={media}
              title="Custom Lists"
              active={splitLocation[1] === ''}
              to={Constants.PAGES_URL.CUSTOM_LISTS}
              webIcon={customListsNav}
              mobileIcon={customListsMobileNav}
            />
          )}
          {credit_check_services && (
            <NavigationItem
              media={media}
              title="Easy Credit Check"
              active={splitLocation[1] === ''}
              to={Constants.PAGES_URL.EASY_CREDIT_CHECK}
              webIcon={easyCreditCheckNav}
              mobileIcon={easyCreditCheckMobileNav}
            />
          )}
        </div>
        {baas && permittedLinks(USER_PERMISSIONS.baasPermissions) && (
          <p className="text-xs text-[#73739B] pt-6 pb-2 pl-4 uppercase">
            FINANCIAL SERVICES
          </p>
        )}

        {baas && permittedLinks(USER_PERMISSIONS.baasPermissions) && (
          <div className="flex flex-col gap-2 rounded lg:text-white text-grey">
            <SubNavigationDropdown
              media={media}
              toggleSubRoute={() => toggleSubRoute('baas')}
              src={media ? baasIconWhite : baasIconBlack}
              open={openDropdown === 'baas'}
              label="BAAS"
            />

            {openDropdown === 'baas' && (
              <div className="flex flex-col">
                {canViewOverview && (
                  <NavigationItem
                    media={media}
                    title=" Overview"
                    active={splitLocation[1] === ''}
                    to={Constants.PAGES_URL.BAAS_OVERVIEW}
                    webIcon={baasOverview}
                    mobileIcon={baasOverview}
                    inner
                  />
                )}
                {canViewTransactions && (
                  <NavigationItem
                    media={media}
                    title="Transactions"
                    active={splitLocation[1] === ''}
                    to={Constants.PAGES_URL.BAAS_TRANSACTIONS}
                    webIcon={baasTransactions}
                    mobileIcon={baasTransactions}
                    inner
                  />
                )}
                {canViewWallets && (
                  <NavigationItem
                    media={media}
                    title="Wallets"
                    active={splitLocation[1] === ''}
                    to={Constants.PAGES_URL.BAAS_WALLETS}
                    webIcon={baasWallets}
                    mobileIcon={baasWallets}
                    inner
                  />
                )}
                {canViewPayouts && (
                  <NavigationItem
                    media={media}
                    title="Payouts"
                    active={splitLocation[1] === ''}
                    to={Constants.PAGES_URL.BAAS_PAYOUTS}
                    webIcon={baasPayouts}
                    mobileIcon={baasPayouts}
                    inner
                  />
                )}
                {canManageSettings && (
                  <NavigationItem
                    media={media}
                    title="BAAS Settings"
                    active={splitLocation[1] === ''}
                    to={Constants.PAGES_URL.BAAS_SETTINGS}
                    webIcon={baasSettings}
                    mobileIcon={baasSettings}
                    inner
                  />
                )}
              </div>
            )}
          </div>
        )}

        {(!messaging_report_services && !easy_detect_report_services) ||
          Object.values(USER_PERMISSIONS?.analyticsPermissions)?.every(
            s => !s,
          ) || (
            <p className="text-xs text-[#73739B] pt-6 pb-2 pl-4 uppercase">
              REPORT ANALYTICS
            </p>
          )}
        <div className="flex flex-col gap-2">
          {/*{(USER_PERMISSIONS?.analyticsPermissions?.viewApi ||*/}
          {/*  USER_PERMISSIONS?.analyticsPermissions?.exportApi) &&*/}
          {/*  api_report_services && (*/}
          {/*    <NavigationItem*/}
          {/*      media={media}*/}
          {/*      title="API Report"*/}
          {/*      active={splitLocation[1] === ''}*/}
          {/*      to={Constants.PAGES_URL.API_REPORT}*/}
          {/*      webIcon={apiReportNav}*/}
          {/*      mobileIcon={apiReportMobileNav}*/}
          {/*    />*/}
          {/*  )}*/}
          {(USER_PERMISSIONS?.analyticsPermissions?.viewMessaging ||
            USER_PERMISSIONS?.analyticsPermissions?.exportMessaging) &&
            messaging_report_services && (
              <NavigationItem
                media={media}
                title="Messaging Logs"
                active={splitLocation[1] === ''}
                to={Constants.PAGES_URL.MESSAGING_REPORT}
                webIcon={messagingReportNav}
                mobileIcon={messagingReportMobileNav}
              />
            )}
          {(USER_PERMISSIONS?.analyticsPermissions?.viewEasyDetect ||
            USER_PERMISSIONS?.analyticsPermissions?.exportEasyDetect) &&
            easy_detect_report_services && (
              <NavigationItem
                media={media}
                title="Easy Detect Report"
                active={splitLocation[1] === ''}
                to={Constants.PAGES_URL.EASY_DETECT_REPORT}
                webIcon={easyDetectNav}
                mobileIcon={easyDetectMobileNav}
              />
            )}
        </div>
        {permittedLinks(USER_PERMISSIONS?.developerPermissions) &&
          developers_services && (
            <div className="mt-6 flex flex-col gap-2 rounded lg:text-white text-grey">
              <SubNavigationDropdown
                media={media}
                toggleSubRoute={() => toggleSubRoute('developers')}
                src={media ? developersNav : developersMobileNav}
                open={openDropdown === 'developers'}
                label="Developers"
              />

              {openDropdown === 'developers' && (
                <div className="flex flex-col">
                  {api_logs_services && canViewApiLogs && (
                    <NavigationItem
                      media={media}
                      title="API Logs"
                      active={splitLocation[1] === ''}
                      to={Constants.PAGES_URL.DEVELOPERS_API_LOGS}
                      webIcon={apiLogsNav}
                      mobileIcon={apiLogsMobileNav}
                      inner
                    />
                  )}
                  {permittedLinks(USER_PERMISSIONS?.developerPermissions) && (
                    <NavigationItem
                      media={media}
                      title="Configuration"
                      active={splitLocation[1] === ''}
                      to={Constants.PAGES_URL.DEVELOPERS_CONFIGURATION}
                      webIcon={configurationNav}
                      mobileIcon={configurationMobileNav}
                      inner
                    />
                  )}

                  {canManageTokens && (
                    <NavigationItem
                      media={media}
                      title="API Tokens"
                      active={splitLocation[1] === ''}
                      to={Constants.PAGES_URL.API_TOKENS}
                      webIcon={apiLogsNav}
                      mobileIcon={apiLogsMobileNav}
                      inner
                    />
                  )}
                  {webhookPermission && (
                    <NavigationItem
                      media={media}
                      title="Webhooks"
                      active={splitLocation[1] === ''}
                      to={Constants.PAGES_URL.DEVELOPERS_WEBHOOK}
                      webIcon={webhookNav}
                      mobileIcon={webhookMobileNav}
                      inner
                    />
                  )}
                  {!is_partner_company && (
                    <a
                      href="https://api-docs.dojah.io/"
                      target="_blank"
                      rel="noreferrer"
                      className={`rounded lg:text-white text-grey flex items-center gap-2 pl-8 py-2 relative ${splitLocation[1]} === "" ? "active" : ""`}
                    >
                      <div className="lg:hidden xl:block">
                        <img
                          src={
                            media ? documentationNav : documentationMobileNav
                          }
                          alt=""
                          width={18}
                          height={18}
                        />
                      </div>
                      <span className="text-sm lg:text-white text-grey -mb-1 mr-[7px]">
                        Documentation
                      </span>
                      <img
                        src={media ? hyperLinkWhite : hyperLinkGrey}
                        alt=""
                        width={14}
                        height={14}
                      />
                    </a>
                  )}
                </div>
              )}
            </div>
          )}
        {billings_services && (canViewHistory || canViewApiCalls) && (
          <div className="mt-2 flex flex-col gap-2 rounded lg:text-white text-grey">
            <SubNavigationDropdown
              media={media}
              toggleSubRoute={() => toggleSubRoute('billing')}
              src={media ? billingNav : billingMobileNav}
              open={openDropdown === 'billing'}
              label="Billings"
            />

            {openDropdown === 'billing' && (
              <div className="flex flex-col">
                {canViewHistory && canManageFlows && (
                  <NavigationItem
                    media={media}
                    title="Transaction history"
                    active={pathname === Constants.PAGES_URL.SETTINGS_BILLING}
                    to={Constants.PAGES_URL.SETTINGS_BILLING}
                    webIcon={transactionHistoryNav}
                    mobileIcon={transactionHistoryMobileNav}
                    inner
                  />
                )}
                {canReviewCases && canViewApiCalls && (
                  <NavigationItem
                    media={media}
                    title="API calls"
                    active={
                      pathname === Constants.PAGES_URL.SETTINGS_BILLING_PRICING
                    }
                    to={Constants.PAGES_URL.SETTINGS_BILLING_PRICING}
                    webIcon={billingNav}
                    mobileIcon={billingMobileNav}
                    inner
                  />
                )}

                {(api_report_services || easy_detect_report_services) && (
                  <div className="flex flex-col">
                    <div className="flex-col hidden lg:flex"></div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div className="flex flex-col mt-2">
          {(profile_services || teams_services || audit_logs_services) && (
            <SubNavigationDropdown
              media={media}
              toggleSubRoute={() => toggleSubRoute('settings')}
              src={media ? settingsNav : settingsMobileNav}
              open={openDropdown === 'settings'}
              label="Settings"
            />
          )}

          {openDropdown === 'settings' && (
            <>
              <NavLink
                to={Constants.PAGES_URL.SETTINGS_PROFILE}
                className={`nav-item rounded flex lg:hidden items-center gap-2 pl-8 py-2 relative ${splitLocation[1]} === "" ? "active" : ""`}
                aria-current="page"
              >
                <img src={settingsMobileNav} alt="" width={18} height={18} />
                <span className="-mb-1 text-sm text-body">All settings</span>
              </NavLink>
              <div className="flex-col hidden lg:flex">
                {profile_services && (
                  <NavigationItem
                    media={media}
                    title="Profile"
                    active={splitLocation[1] === ''}
                    to={Constants.PAGES_URL.SETTINGS_PROFILE}
                    webIcon={profileNav}
                    mobileIcon={settingsMobileNav}
                    inner
                  />
                )}

                {Object.values(
                  USER_PERMISSIONS?.settingsPermissions || {},
                ).some(s => !!s) && (
                  <>
                    {USER_PERMISSIONS?.settingsPermissions?.manageMembers &&
                      teams_services &&
                      managePermissions && (
                        <NavigationItem
                          media={media}
                          title="Team"
                          active={splitLocation[1] === ''}
                          to={Constants.PAGES_URL.SETTINGS_TEAM}
                          webIcon={teamNav}
                          mobileIcon={teamNav}
                          inner
                        />
                      )}
                    {USER_PERMISSIONS?.settingsPermissions
                      ?.viewAndDownloadAudit &&
                      audit_logs_services &&
                      manageAuditLogs && (
                        <NavigationItem
                          media={media}
                          title="Audit Logs"
                          active={splitLocation[1] === ''}
                          to={Constants.PAGES_URL.SETTINGS_AUDIT_LOGS}
                          webIcon={auditLogsNav}
                          mobileIcon={auditLogsNav}
                          inner
                        />
                      )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  },
);

export {MainSideNavigations};
