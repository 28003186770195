import React from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {useQuery} from 'react-query';
import Layout from '../Layout';
import {Accordion, EmptyState, Loader} from '../../../components';
import {
  corruptedFile,
  nigeriaIc,
  southAfricaIc,
} from '../../../assets/images/images';
import {fetchBusinessEntity} from '../../../requests/queries/business360';
import {getInitials, IGNORE_KEYS} from '../LayoutConstants';
import moment from 'moment';

const getCountryLogo = country =>
  ({
    nigeria: nigeriaIc,
    ng: nigeriaIc,
    'south-africa': southAfricaIc,
    sa: southAfricaIc,
  }[country?.toLowerCase() || 'nigeria']);

const formatValue = (key, value) => {
  if (value === null || value === undefined) return '-';
  if (typeof value === 'object') return JSON.stringify(value);
  if (
    [
      'date_of_registration',
      'last_updated_date',
      'createdat',
      'updatedat',
    ].includes(key.toLowerCase())
  ) {
    return moment(value).isValid()
      ? moment(value).format('DD MMM YYYY HH:mm')
      : '-';
  }
  return String(value).replace(/_/g, ' ');
};

export default function BusinessLookup() {
  const {slug: businessId} = useParams();
  const location = useLocation();
  const entityTypes = location.state?.entityTypes || [];

  const {data: businessEntity, isLoading} = useQuery(
    ['business-entity', businessId, entityTypes],
    () =>
      fetchBusinessEntity({
        customer_id: businessId,
        entity_type: entityTypes.join(','),
      }),
    {
      enabled: !!businessId && entityTypes.length > 0,
    },
  );

  const isEmpty =
    !businessEntity ||
    !businessEntity.entity ||
    Object.values(businessEntity.entity).every(value => value === null);

  const getCountryName = entityKey => {
    const parts = entityKey.split('_');
    return parts[0].toUpperCase();
  };

  if (isLoading) {
    return (
      <Layout>
        <Loader height={50} />
      </Layout>
    );
  }

  return (
    <Layout>
      {isEmpty ? (
        <div className="bg-white80 w-full">
          <EmptyState
            body="There is no information to display here at the moment"
            src={corruptedFile}
            height={45}
            imageHeight={48}
            imageWidth={48}
            bodyMaxWidth="max-w-[179px]"
            buttonText="Validate document"
          />
        </div>
      ) : (
        <div className="space-y-4">
          {businessEntity &&
            businessEntity.entity &&
            Object.entries(businessEntity.entity).map(
              ([entityKey, entityData], index) => {
                if (!entityData || typeof entityData !== 'object') return null;
                const countryName = getCountryName(entityKey);

                return (
                  <Accordion
                    key={index}
                    title={entityKey.replace(/^NG_/i, '').toUpperCase()}
                    border
                    customClasses="rounded-xl"
                    customComponent={
                      <div className="flex items-center gap-2 rounded-full px-2 py-1 outline outline-1 outline-grey-50 bg-white80">
                        <p className="text-deepBlue text-xs font-medium uppercase -mb-1">
                          {countryName}
                        </p>
                        <div className="w-5 h-5 flex items-center justify-center bg-blue-50 outline outline-1 outline-blue-100 rounded-full">
                          <img
                            src={getCountryLogo(countryName.toLowerCase())}
                            alt=""
                            width={12}
                            height={8}
                          />
                        </div>
                      </div>
                    }
                    customChildrenClasses="mt-2 border-t border-grey-50"
                    customPadding="px-6 py-5"
                    noChildPadding
                    customTitleSize="text-lg"
                    customTitleColor="text-grey"
                  >
                    {entityData && (
                      <div className="w-full inline-grid grid-cols-1 md:grid-cols-2 xl:grid-cols-[auto_auto_auto_auto] items-start gap-10 justify-between pl-8 pr-10 pt-6 pb-14">
                        <div className="rounded-full w-[89px] h-[89px] flex items-center justify-center text-xxl font-medium text-blue bg-brandBlue40">
                          <span className="-mb-2">
                            {getInitials(
                              entityData.company_name ||
                                entityData.Name_of_Company ||
                                '',
                              2,
                            )}
                          </span>
                        </div>

                        {Object.entries(entityData).map(
                          ([key, value], index) =>
                            !IGNORE_KEYS.includes(key) &&
                            value && (
                              <div key={index} className="flex flex-col gap-2">
                                {!['image', 'photo'].includes(key) && (
                                  <p className="text-grey-700 text-xs font-medium uppercase">
                                    {key.replace(/_/g, ' ')}
                                  </p>
                                )}

                                <p className="text-grey text-sm  font-medium">
                                  {formatValue(key, value)}
                                </p>
                              </div>
                            ),
                        )}
                      </div>
                    )}
                  </Accordion>
                );
              },
            )}
        </div>
      )}
    </Layout>
  );
}
