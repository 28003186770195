import React, {useState, useMemo, useEffect} from 'react';
import {Link, NavLink, useParams, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {Checkbox, PrimaryButton} from '../../../components';
import Loader from '../../../components/Loader';
import * as Actions from '../../../store/actions';
import {
  chevronLeft,
  chevronRight,
  plusGrey,
  teamIc,
} from '../../../assets/images/images';
import DashboardLayout from '../../../components/DashboardLayout';
import {Constants} from '../../../helpers';
import {PAGES_URL} from '../../../helpers/constants';

function Permission({permission, disabled = false, ...rest}) {
  return (
    <div
      className={`flex items-center ${disabled ? 'cursor-not-allowed' : ''}`}
    >
      <Checkbox noLeftPadding {...rest} disabled={disabled} />
      <span
        title={permission}
        className={`text-sm truncated-text
      ${disabled ? 'text-grey30' : 'text-body'}
      `}
      >
        {permission}
      </span>
    </div>
  );
}

const SettingsTeamLayout = ({
  children,
  team: {
    roles: existingRoles,
    loading,
    editTeamRoleLoading,
    deleteTeamRoleLoading,
  },
  editTeamRole,
  addTeamRole,
  deleteTeamRole,
  auth: {userDetails},
}) => {
  const navigate = useNavigate();
  const allRoles = useMemo(
    () =>
      existingRoles
        ?.filter(r => r.role !== 'Owner')
        ?.map(r => ({
          role: r.role,
          path: `/settings/team/${r.role.toLowerCase().replaceAll(' ', '-')}`,
          id: r.id,
          reconciliationPermissions: r.reconciliationPermissions,
          customListPermissions: r.customListPermissions,
          lookupPermissions: r.lookupPermissions,
          settingsPermissions: r.settingsPermissions,
          onboardPermissions: r.onboardPermissions,
          amlScreeningPermissions: r.amlScreeningPermissions,
          detectPermissions: r.detectPermissions,
          quickActionPermissions: r.quickActionPermissions,
          addressVerificationPermissions: r.addressVerificationPermissions,
          billingPermissions: r.billingPermissions,
          baasPermissions: r.baasPermissions,
          documentAnalysisPermissions: r.documentAnalysisPermissions,
          developerPermissions: r.developerPermissions,
          analyticsPermissions: r.analyticsPermissions,
        })),
    [existingRoles],
  );

  const [roles, setRoles] = useState(allRoles);
  const [role, setRole] = useState('');

  const [analyticsPermissions, setAnalyticsPermissions] = useState({});
  const [allAnalyticsPermissions, setAllAnalyticsPermissions] = useState(false);
  const [customListPermissions, setCustomListPermissions] = useState({});
  const [allCustomListPermissions, setAllCustomListPermissions] =
    useState(false);
  const [reconciliationPermissions, setReconciliationPermissions] = useState(
    {},
  );
  const [allReconciliationPermissions, setAllReconciliationPermissions] =
    useState(false);
  const [lookupPermissions, setLookupPermissions] = useState({});
  const [allLookupPermissions, setAllLookupPermissions] = useState(false);
  const [settingsPermissions, setSettingsPermissions] = useState({});
  const [allSettingsPermissions, setAllSettingsPermissions] = useState(false);
  const [onboardPermissions, setOnboardPermissions] = useState({});
  const [allOnboardPermissions, setAllOnboardPermissions] = useState(false);
  const [billingPermissions, setBillingPermissions] = useState({});
  const [baasPermissions, setBAASPermissions] = useState({});
  const [allBaasPermissions, setAllBaasPermissions] = useState(false);
  const [allBillingPermissions, setAllBillingPermissions] = useState(false);
  const [amlScreeningPermissions, setAmlScreeningPermissions] = useState({});
  const [allAmlScreeningPermissions, setAllAmlScreeningPermissions] =
    useState(false);

  const [apiReport, setApiReport] = useState(false);
  const [messagingLogs, setMessagingLogs] = useState(false);
  const [detectReport, setDetectReport] = useState(false);

  const [userScreening, setUserScreening] = useState(false);
  const [businessScreening, setBusinessScreening] = useState(false);

  const [developerPermissions, setDeveloperPermissions] = useState({});
  const [allDeveloperPermissions, setAllDeveloperPermissions] = useState(false);
  const [documentAnalysisPermissions, setDocumentAnalysisPermissions] =
    useState({});
  const [allDocumentAnalysisPermissions, setAllDocumentAnalysisPermissions] =
    useState(false);
  const [detectPermissions, setDetectPermissions] = useState({});
  const [allDetectPermissions, setAllDetectPermissions] = useState(false);
  const [quickActionPermissions, setQuickActionPermissions] = useState({});
  const [allQuickActionPermissions, setAllQuickActionPermissions] =
    useState(false);
  const [addressVerificationPermissions, setAddressVerificationPermissions] =
    useState({});
  const [
    allAddressVerificationPermissions,
    setAllAddressVerificationPermissions,
  ] = useState(false);
  const [allPermissions, setAllPermissions] = useState(false);

  const [individualLookup, setIndividualLookup] = useState(false);
  const [businessLookup, setBusinessLookup] = useState(false);

  const {slug} = useParams();

  const currentRole = useMemo(
    () =>
      allRoles?.find(r => r.role.toLowerCase().replaceAll(' ', '-') === slug),
    [slug, allRoles],
  );

  const untitledRoleExists = useMemo(
    () => allRoles?.some(r => r.role === 'Untitled'),
    [allRoles],
  );

  const USER_COMPANY = userDetails && userDetails?.company;
  const {
    baas,
    address_verification_services,
    aml_screening_services,
    api_report_services,
    audit_logs_services,
    api_logs_services,
    billings_services,
    developers_services,
    easy_detect_report_services,
    easy_lookup_services,
    easy_onboard_services,
    quick_actions_services,
    teams_services,
    custom_link,
    reconciliation,
    messaging_report_services,
    document_analysis,
    easy_detect_services,
    // eslint-disable-next-line no-unsafe-optional-chaining
  } = USER_COMPANY?.modularisation || {};

  useEffect(() => {
    if (currentRole) {
      setRole(currentRole.role);
      setAnalyticsPermissions(currentRole.analyticsPermissions);
      setReconciliationPermissions(currentRole.reconciliationPermissions);
      setLookupPermissions(currentRole.lookupPermissions);
      setSettingsPermissions(currentRole.settingsPermissions);
      setOnboardPermissions(currentRole.onboardPermissions);
      setAmlScreeningPermissions(currentRole.amlScreeningPermissions);
      setDetectPermissions(currentRole.detectPermissions);
      setQuickActionPermissions(currentRole.quickActionPermissions);
      setAddressVerificationPermissions(
        currentRole.addressVerificationPermissions,
      );
      setBillingPermissions(currentRole.billingPermissions);
      setBAASPermissions(currentRole.baasPermissions);
      setDocumentAnalysisPermissions(currentRole.documentAnalysisPermissions);
      setDeveloperPermissions(currentRole.developerPermissions);
      setCustomListPermissions(currentRole.customListPermissions);
    }
  }, [currentRole]);
  useEffect(() => {
    if (analyticsPermissions) {
      setAllAnalyticsPermissions(
        Object.values(analyticsPermissions).every(v => v),
      );
    }
  }, [analyticsPermissions]);
  useEffect(() => {
    if (customListPermissions) {
      setAllCustomListPermissions(
        Object.values(customListPermissions).every(v => v),
      );
    }
  }, [customListPermissions]);
  useEffect(() => {
    if (reconciliationPermissions) {
      setAllReconciliationPermissions(
        Object.values(reconciliationPermissions).every(v => v),
      );
    }
  }, [reconciliationPermissions]);
  useEffect(() => {
    if (developerPermissions) {
      setAllDeveloperPermissions(
        Object.values(developerPermissions).every(v => v),
      );
    }
  }, [developerPermissions]);
  useEffect(() => {
    if (documentAnalysisPermissions) {
      setAllDocumentAnalysisPermissions(
        Object.values(documentAnalysisPermissions).every(v => v),
      );
    }
  }, [documentAnalysisPermissions]);
  useEffect(() => {
    if (billingPermissions) {
      setAllBillingPermissions(Object.values(billingPermissions).every(v => v));
    }
  }, [billingPermissions]);
  useEffect(() => {
    if (baasPermissions) {
      setAllBaasPermissions(Object.values(baasPermissions).every(v => v));
    }
  }, [baasPermissions]);
  useEffect(() => {
    if (lookupPermissions) {
      setAllLookupPermissions(Object.values(lookupPermissions).every(v => v));
      setIndividualLookup(
        lookupPermissions.performSingleIndividualLookup &&
          lookupPermissions.viewSingleIndividualLookup &&
          lookupPermissions.downloadIndividualLookup &&
          lookupPermissions.performBatchIndividualLookup &&
          lookupPermissions.viewBatchIndividualLookup,
      );
      setBusinessLookup(
        lookupPermissions.performSingleBusinessLookup &&
          lookupPermissions.viewSingleBusinessLookup &&
          lookupPermissions.downloadBusinessLookup &&
          lookupPermissions.performBatchBusinessLookup &&
          lookupPermissions.viewBatchBusinessLookup,
      );
    }
  }, [lookupPermissions]);
  useEffect(() => {
    if (addressVerificationPermissions) {
      setAllAddressVerificationPermissions(
        Object.values(addressVerificationPermissions).every(v => v),
      );
    }
  }, [addressVerificationPermissions]);
  useEffect(() => {
    setAllSettingsPermissions(Object.values(settingsPermissions).every(v => v));
  }, [settingsPermissions]);
  useEffect(() => {
    if (onboardPermissions) {
      setAllOnboardPermissions(Object.values(onboardPermissions).every(v => v));
    }
  }, [onboardPermissions]);
  useEffect(() => {
    if (amlScreeningPermissions) {
      setAllAmlScreeningPermissions(
        Object.values(amlScreeningPermissions).every(v => v),
      );
      setUserScreening(
        amlScreeningPermissions.performSingleIndividualLookup &&
          amlScreeningPermissions.viewSingleIndividualLookup &&
          amlScreeningPermissions.performBatchIndividualLookup &&
          amlScreeningPermissions.viewBatchIndividualLookup,
      );
      setBusinessScreening(
        amlScreeningPermissions.performSingleBusinessLookup &&
          amlScreeningPermissions.viewSingleBusinessLookup &&
          amlScreeningPermissions.performBatchBusinessLookup &&
          amlScreeningPermissions.viewBatchBusinessLookup,
      );
    }
  }, [amlScreeningPermissions]);
  useEffect(() => {
    if (analyticsPermissions) {
      setAllAnalyticsPermissions(
        Object.values(analyticsPermissions).every(v => v),
      );
      setApiReport(
        analyticsPermissions.viewApi && analyticsPermissions.exportApi,
      );
      setMessagingLogs(
        analyticsPermissions.viewMessaging &&
          analyticsPermissions.exportMessaging,
      );
      setDetectReport(
        analyticsPermissions.viewEasyDetect &&
          analyticsPermissions.exportEasyDetect,
      );
    }
  }, [analyticsPermissions]);
  useEffect(() => {
    if (detectPermissions) {
      setAllDetectPermissions(Object.values(detectPermissions).every(v => v));
    }
  }, [detectPermissions]);
  useEffect(() => {
    if (quickActionPermissions) {
      setAllQuickActionPermissions(
        Object.values(quickActionPermissions).every(v => v),
      );
    }
  }, [quickActionPermissions]);

  useEffect(() => {
    setAllPermissions(
      [
        allLookupPermissions,
        allOnboardPermissions,
        allSettingsPermissions,
        allAmlScreeningPermissions,
        allDetectPermissions,
        allQuickActionPermissions,
        allAddressVerificationPermissions,
        allBillingPermissions,
        allBaasPermissions,
        allDocumentAnalysisPermissions,
        allDeveloperPermissions,
        allReconciliationPermissions,
        allCustomListPermissions,
        allAnalyticsPermissions,
        userScreening,
        businessScreening,
        individualLookup,
        businessLookup,
        apiReport,
        messagingLogs,
        detectReport,
      ].every(v => v),
    );
  }, [
    allLookupPermissions,
    allOnboardPermissions,
    allSettingsPermissions,
    allAmlScreeningPermissions,
    allDetectPermissions,
    allQuickActionPermissions,
    allAddressVerificationPermissions,
    allBillingPermissions,
    allBaasPermissions,
    allDocumentAnalysisPermissions,
    allDeveloperPermissions,
    allReconciliationPermissions,
    allCustomListPermissions,
    allAnalyticsPermissions,
    userScreening,
    businessScreening,
    individualLookup,
    businessLookup,
    apiReport,
    messagingLogs,
    detectReport,
  ]);

  const createNewRole = (roleName = 'Untitled') => {
    if (untitledRoleExists)
      return toast.error("You cannot have multiple 'Untitled' roles");
    addTeamRole({role: roleName}, updatedRoles =>
      updateRoles(updatedRoles, roleName),
    );
  };
  const deleteRole = () => {
    deleteTeamRole(currentRole.id, updatedRoles =>
      updateRoles(updatedRoles, 'Owner'),
    );
  };
  const updateRole = () => {
    if (role === 'Untitled' || role === '') {
      toast.error(
        "Role name cannot be 'Untitled' or blank. Please choose a different name.",
      );
      return;
    }
    if (
      allRoles?.some(
        r =>
          r.role.toLowerCase() === role.toLowerCase() &&
          r.id !== currentRole.id,
      )
    ) {
      toast.error('Role name already exists. Please choose a different name.');
      return;
    }
    delete billingPermissions.viewAndExportLogs;
    editTeamRole(
      currentRole.id,
      {
        role,
        reconciliationPermissions,
        lookupPermissions,
        settingsPermissions,
        onboardPermissions,
        amlScreeningPermissions,
        detectPermissions,
        quickActionPermissions,
        addressVerificationPermissions,
        billingPermissions,
        baasPermissions,
        documentAnalysisPermissions,
        developerPermissions,
        customListPermissions,
        analyticsPermissions,
      },
      updatedRoles => updateRoles(updatedRoles, role),
    );
  };
  const updateRoles = (updatedRoles, role) => {
    const newPath = `/settings/team/${role.toLowerCase().replaceAll(' ', '-')}`;
    setRoles(
      updatedRoles
        ?.filter(r => r.role !== 'Owner')
        ?.map(r => ({
          role: r.role,
          path: `/settings/team/${r.role.toLowerCase().replaceAll(' ', '-')}`,
          id: r.id,
          reconciliationPermissions: r.reconciliationPermissions,
          lookupPermissions: r.lookupPermissions,
          settingsPermissions: r.settingsPermissions,
          onboardPermissions: r.onboardPermissions,
          amlScreeningPermissions: r.amlScreeningPermissions,
          detectPermissions: r.detectPermissions,
          quickActionPermissions: r.quickActionPermissions,
          addressVerificationPermissions: r.addressVerificationPermissions,
          billingPermissions: r.billingPermissions,
          documentAnalysisPermissions: r.documentAnalysisPermissions,
          developerPermissions: r.developerPermissions,
          customListPermissions: r.customListPermissions,
          analyticsPermissions: r.analyticsPermissions,
          baasPermissions: r.baasPermissions,
        })),
    );
    navigate(newPath);
  };

  const changeAnalyticsPermissions = permission => {
    setAnalyticsPermissions(prevState => {
      if (!prevState) {
        return {
          [permission]: true,
        };
      }

      return {
        ...prevState,
        [permission]: !prevState[permission],
      };
    });
  };
  const changeCustomListPermissions = permission => {
    setCustomListPermissions(prevState => {
      if (!prevState) {
        return {
          [permission]: true,
        };
      }

      return {
        ...prevState,
        [permission]: !prevState[permission],
      };
    });
  };
  const changeReconciliationPermissions = permission => {
    setReconciliationPermissions(prevState => {
      if (!prevState) {
        return {
          [permission]: true,
        };
      }

      return {
        ...prevState,
        [permission]: !prevState[permission],
      };
    });
  };
  const changeDeveloperPermissions = permission => {
    setDeveloperPermissions(prevState => {
      if (!prevState) {
        return {
          [permission]: true,
        };
      }

      return {
        ...prevState,
        [permission]: !prevState[permission],
      };
    });
  };
  const changeDocumentAnalysisPermissions = permission => {
    setDocumentAnalysisPermissions(prevState => {
      if (!prevState) {
        return {
          [permission]: true,
        };
      }

      return {
        ...prevState,
        [permission]: !prevState[permission],
      };
    });
  };
  const changeBillingPermissions = permission => {
    setBillingPermissions(prevState => {
      if (!prevState) {
        return {
          [permission]: true,
        };
      }

      return {
        ...prevState,
        [permission]: !prevState[permission],
      };
    });
  };
  const changeBaasPermissions = permission => {
    setBAASPermissions(prevState => {
      if (!prevState) {
        return {
          [permission]: true,
        };
      }

      return {
        ...prevState,
        [permission]: !prevState[permission],
      };
    });
  };
  const toggleCustomListPermissions = option => {
    setCustomListPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = option ?? !allCustomListPermissions;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllCustomListPermissions(option ?? !allCustomListPermissions);
  };
  const toggleReconciliationPermissions = option => {
    setReconciliationPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = option ?? !allReconciliationPermissions;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllReconciliationPermissions(option ?? !allReconciliationPermissions);
  };
  const toggleDocumentAnalysisPermissions = option => {
    setDocumentAnalysisPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = option ?? !allDocumentAnalysisPermissions;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllDocumentAnalysisPermissions(
      option ?? !allDocumentAnalysisPermissions,
    );
  };
  const toggleBillingPermissions = option => {
    setBillingPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = option ?? !allBillingPermissions;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllBillingPermissions(option ?? !allBillingPermissions);
  };
  const toggleBAASPermissions = option => {
    setBAASPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = option ?? !allBaasPermissions;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllBaasPermissions(option ?? !allBaasPermissions);
  };
  const toggleDeveloperPermissions = option => {
    setDeveloperPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = option ?? !allDeveloperPermissions;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllDeveloperPermissions(option ?? !allDeveloperPermissions);
  };
  const toggleLookupPermissions = option => {
    const newOption = option ?? !allAmlScreeningPermissions;
    setLookupPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = newOption;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllLookupPermissions(newOption);
    toggleIndividualLookup(newOption);
    toggleBusinessLookup(newOption);
  };
  const toggleIndividualLookup = option => {
    setIndividualLookup(option);
    setLookupPermissions(prevState => ({
      ...prevState,
      performSingleIndividualLookup: option,
      viewSingleIndividualLookup: option,
      downloadIndividualLookup: option,
      performBatchIndividualLookup: option,
      viewBatchIndividualLookup: option,
    }));
  };
  const toggleBusinessLookup = option => {
    setBusinessLookup(option);
    setLookupPermissions(prevState => ({
      ...prevState,
      performSingleBusinessLookup: option,
      viewSingleBusinessLookup: option,
      downloadBusinessLookup: option,
      performBatchBusinessLookup: option,
      viewBatchBusinessLookup: option,
    }));
  };

  const toggleSettingsPermissions = option => {
    setSettingsPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = option ?? !allSettingsPermissions;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllSettingsPermissions(option ?? !allSettingsPermissions);
  };
  const toggleAddressVerificationPermissions = option => {
    setAddressVerificationPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = option ?? !allAddressVerificationPermissions;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllAddressVerificationPermissions(
      option ?? !allAddressVerificationPermissions,
    );
  };
  const toggleOnboardPermissions = option => {
    setOnboardPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = option ?? !allOnboardPermissions;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllOnboardPermissions(option ?? !allOnboardPermissions);
  };
  const toggleAmlScreeningPermissions = option => {
    const newOption = option ?? !allAmlScreeningPermissions;
    setAmlScreeningPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = newOption;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllAmlScreeningPermissions(newOption);
    toggleUserScreening(newOption);
    toggleBusinessScreening(newOption);
  };
  const toggleAnalyticsPermissions = option => {
    const newOption = option ?? !allAnalyticsPermissions;
    setAnalyticsPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = newOption;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllAnalyticsPermissions(newOption);
    toggleApiReport(newOption);
    toggleMessagingLogs(newOption);
    toggleDetectReport(newOption);
  };
  const toggleApiReport = option => {
    setApiReport(option);
    setAnalyticsPermissions(prevState => ({
      ...prevState,
      viewApi: option,
      exportApi: option,
    }));
  };
  const toggleMessagingLogs = option => {
    setMessagingLogs(option);
    setAnalyticsPermissions(prevState => ({
      ...prevState,
      viewMessaging: option,
      exportMessaging: option,
    }));
  };
  const toggleDetectReport = option => {
    setDetectReport(option);
    setAnalyticsPermissions(prevState => ({
      ...prevState,
      viewEasyDetect: option,
      exportEasyDetect: option,
    }));
  };
  const toggleUserScreening = option => {
    setUserScreening(option);
    setAmlScreeningPermissions(prevState => ({
      ...prevState,
      performSingleIndividualLookup: option,
      viewSingleIndividualLookup: option,
      performBatchIndividualLookup: option,
      viewBatchIndividualLookup: option,
    }));
  };
  const toggleBusinessScreening = option => {
    setBusinessScreening(option);
    setAmlScreeningPermissions(prevState => ({
      ...prevState,
      performSingleBusinessLookup: option,
      viewSingleBusinessLookup: option,
      performBatchBusinessLookup: option,
      viewBatchBusinessLookup: option,
    }));
  };

  const toggleDetectPermissions = option => {
    setDetectPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = option ?? !allDetectPermissions;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllDetectPermissions(option ?? !allDetectPermissions);
  };
  const toggleQuickActionPermissions = option => {
    setQuickActionPermissions(prevState => {
      if (prevState) {
        Object.keys(prevState).forEach(key => {
          prevState[key] = option ?? !allQuickActionPermissions;
        });
        return prevState;
      } else {
        return {};
      }
    });
    setAllQuickActionPermissions(option ?? !allQuickActionPermissions);
  };
  const changeDetectPermissions = permission => {
    setDetectPermissions(prevState => {
      if (!prevState) {
        return {
          [permission]: true,
        };
      }

      return {
        ...prevState,
        [permission]: !prevState[permission],
      };
    });
  };
  const changeQuickActionPermissions = permission => {
    setQuickActionPermissions(prevState => {
      if (!prevState) {
        return {
          [permission]: true,
        };
      }

      return {
        ...prevState,
        [permission]: !prevState[permission],
      };
    });
  };
  const toggleAllPermissions = () => {
    toggleReconciliationPermissions(!allPermissions);
    toggleLookupPermissions(!allPermissions);
    toggleSettingsPermissions(!allPermissions);
    toggleOnboardPermissions(!allPermissions);
    toggleAmlScreeningPermissions(!allPermissions);
    toggleDetectPermissions(!allPermissions);
    toggleQuickActionPermissions(!allPermissions);
    toggleAddressVerificationPermissions(!allPermissions);
    toggleBillingPermissions(!allPermissions);
    toggleBAASPermissions(!allBaasPermissions);
    toggleDocumentAnalysisPermissions(!allPermissions);
    toggleCustomListPermissions(!allPermissions);
    toggleAnalyticsPermissions(!allPermissions);
    toggleDeveloperPermissions(!allPermissions);
    toggleUserScreening(!allPermissions);
    toggleBusinessScreening(!allPermissions);
    toggleBusinessLookup(!allPermissions);
    toggleIndividualLookup(!allPermissions);
    toggleApiReport(!allPermissions);
    toggleMessagingLogs(!allPermissions);
    toggleDetectReport(!allPermissions);
  };
  const changeLookupPermissions = permission => {
    setLookupPermissions(prevState => {
      if (
        permission === 'performIndividualLookupAndViewHistory' ||
        permission === 'performBatchLookupAndViewHistory'
      ) {
        // Toggle performIndividualLookupAndViewHistory and downloadIndividualHistory together
        // Toggle performBatchLookupAndViewHistory and downloadBatchHistory together
        const performLookup = !prevState[permission];
        const downloadHistoryPermission =
          permission === 'performIndividualLookupAndViewHistory'
            ? 'downloadIndividualHistory'
            : 'downloadBatchHistory';
        const downloadHistory = performLookup
          ? prevState[downloadHistoryPermission]
          : false;
        return {
          ...prevState,
          [permission]: performLookup,
          [downloadHistoryPermission]: downloadHistory,
        };
      } else if (
        permission === 'downloadIndividualHistory' &&
        !prevState.performIndividualLookupAndViewHistory
      ) {
        // If downloadIndividualHistory is checked and performIndividualLookupAndViewHistory is unchecked, uncheck downloadIndividualHistory
        return {
          ...prevState,
          downloadIndividualHistory: false,
        };
      } else if (
        permission === 'downloadBatchHistory' &&
        !prevState.performBatchLookupAndViewHistory
      ) {
        // If downloadBatchHistory is checked and performBatchLookupAndViewHistory is unchecked, uncheck downloadBatchHistory
        return {
          ...prevState,
          downloadBatchHistory: false,
        };
      } else {
        // Otherwise, toggle the permission as usual
        return {
          ...prevState,
          [permission]: !prevState[permission],
        };
      }
    });
  };
  const changeSettingsPermissions = permission => {
    setSettingsPermissions(prevState => {
      if (permission === 'viewAudit') {
        // Toggle viewAudit and downloadAudit together
        const viewAudit = !prevState[permission];
        const downloadAudit = viewAudit ? prevState.downloadAudit : false;
        return {
          ...prevState,
          [permission]: viewAudit,
          downloadAudit: downloadAudit,
        };
      } else if (permission === 'downloadAudit' && !prevState.viewAudit) {
        // If downloadAudit is checked and viewAudit is unchecked, uncheck downloadAudit
        return {
          ...prevState,
          downloadAudit: false,
        };
      } else {
        // Otherwise, toggle the permission as usual
        return {
          ...prevState,
          [permission]: !prevState[permission],
        };
      }
    });
  };
  const changeOnboardPermissions = permission => {
    setOnboardPermissions(prevState => {
      if (!prevState) {
        return {
          [permission]: true,
        };
      }

      return {
        ...prevState,
        [permission]: !prevState[permission],
      };
    });
  };
  const changeAddressVerificationPermissions = permission => {
    setAddressVerificationPermissions(prevState => {
      if (!prevState) {
        return {
          [permission]: true,
        };
      }

      return {
        ...prevState,
        [permission]: !prevState[permission],
      };
    });
  };
  const changeAmlScreeningPermissions = permission => {
    setAmlScreeningPermissions(prevState => {
      if (!prevState) {
        return {
          [permission]: true,
        };
      }

      return {
        ...prevState,
        [permission]: !prevState[permission],
      };
    });
  };

  return (
    <DashboardLayout
      xlLeftMargin="xl:ml-12"
      xlRightPadding="xl:pr-[76px]"
      breadCrumbs={
        <div className="flex items-center">
          <img src={teamIc} alt="" />
          <Link className="ml-2" to="/">
            Settings
          </Link>
          <img src={chevronRight} alt="" />
          <Link to="/">Team</Link>
        </div>
      }
    >
      <div className="flex items-center justify-between mt-6 sm:mt-0">
        <button
          onClick={() => navigate(PAGES_URL.SETTINGS_TEAM)}
          className="flex items-end gap-2"
        >
          <img src={chevronLeft} alt="" width={16} height={16} />
          <span className="text-xs font-medium text-grey80">
            Back to settings
          </span>
        </button>
      </div>
      <div className="mt-12">
        <h2 className="text-base font-medium text-grey sm:text-lg">
          Manage Teams & Permissions
        </h2>
        {/* <p className="mt-2 text-sm text-grey30">
          All changes are saved automatically
        </p> */}
      </div>
      <div className="mt-10 border-b border-grey60">
        <div className="flex items-center gap-4 settings-nav whitespace-nowrap hidden-scroll">
          <NavLink
            end
            to={Constants.PAGES_URL.SETTINGS_TEAM_OWNER}
            className={({isActive}) =>
              `text-grey30 font-medium text-sm py-2 ${
                isActive ? ' activeSettingsTab' : ''
              }`
            }
          >
            Owner
          </NavLink>
          {roles?.map(({role, path, id}) => (
            <NavLink
              key={id}
              end
              to={path}
              className={({isActive}) =>
                `text-grey30 font-medium text-sm py-2 ${
                  isActive ? ' activeSettingsTab' : ''
                }`
              }
            >
              {role}
            </NavLink>
          ))}

          <button
            className="flex items-center gap-1"
            type="button"
            onClick={() => createNewRole()}
          >
            <img src={plusGrey} alt="" width={12} height={12} />
            <span className="text-xs font-medium text-grey30">
              Add new role
            </span>
          </button>
        </div>
      </div>

      {loading ? (
        <Loader height={50} />
      ) : currentRole ? (
        <section className="mt-10">
          <div className="flex items-start justify-between">
            <div className="max-w-lg w-full p-4 bg-white rounded">
              <label htmlFor={currentRole.id} className="">
                <span className="text-xs font-medium uppercase text-grey">
                  role name
                </span>
                <input
                  type="text"
                  id={currentRole.id}
                  className="mt-4"
                  onChange={e => setRole(e.target.value)}
                  value={role}
                  placeholder={currentRole.role}
                />
              </label>
            </div>
            <div
              className="flex items-center gap-4"
              style={{...(!slug && {visibility: 'hidden'})}}
            >
              <PrimaryButton
                buttonText="Delete role"
                onClick={deleteRole}
                loading={deleteTeamRoleLoading}
                color="#F15D5F"
                background="#FFFFFF"
              />
              <PrimaryButton
                buttonText="Save changes"
                onClick={updateRole}
                loading={editTeamRoleLoading}
              />
            </div>
          </div>

          <div className="mt-8">
            <div className="flex items-center">
              <Checkbox
                noLeftPadding
                isChecked={allPermissions}
                onChange={toggleAllPermissions}
              />
              <span className="text-sm text-body">All Permissions</span>
            </div>

            <div className="grid grid-cols-4 items-start gap-4 mt-4">
              <div className="flex flex-col gap-4">
                {quick_actions_services && (
                  <div className="rounded p-4 bg-white">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Checkbox
                          noLeftPadding
                          isChecked={allQuickActionPermissions}
                          onChange={() => toggleQuickActionPermissions()}
                        />
                        <span className="text-xs font-medium uppercase text-grey">
                          Quick Actions
                        </span>
                      </div>
                      <div className="py-4 mt-2">
                        <div className="flex flex-col gap-4">
                          <Permission
                            permission="Ability to Top-up and View Wallet"
                            isChecked={quickActionPermissions?.topup}
                            onChange={() =>
                              changeQuickActionPermissions('topup')
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {reconciliation && (
                  <div className="rounded p-4 bg-white">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Checkbox
                          noLeftPadding
                          isChecked={allReconciliationPermissions}
                          onChange={() =>
                            changeReconciliationPermissions('reconciliation')
                          }
                        />
                        <span className="text-xs font-medium uppercase text-grey">
                          Reconciliation
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {custom_link && (
                  <div className="rounded p-4 bg-white">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Checkbox
                          noLeftPadding
                          isChecked={allCustomListPermissions}
                          onChange={() => toggleCustomListPermissions()}
                        />
                        <span className="text-xs font-medium uppercase text-grey">
                          Custom List
                        </span>
                      </div>
                      <div className="py-4 mt-2">
                        <div className="flex flex-col gap-4">
                          <Permission
                            permission="View custom list"
                            isChecked={customListPermissions?.viewCustomList}
                            onChange={() =>
                              changeCustomListPermissions('viewCustomList')
                            }
                          />
                          <Permission
                            permission="Create and edit custom list"
                            isChecked={customListPermissions?.manageCustomList}
                            onChange={() =>
                              changeCustomListPermissions('manageCustomList')
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {easy_onboard_services && (
                  <div className="rounded p-4 bg-white">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Checkbox
                          noLeftPadding
                          isChecked={allOnboardPermissions}
                          onChange={() => toggleOnboardPermissions()}
                        />
                        <span className="text-xs font-medium uppercase text-grey">
                          Easy Onboard
                        </span>
                      </div>
                      <div className="py-4 mt-2">
                        <div className="flex flex-col gap-4">
                          <Permission
                            permission="View Verification flows"
                            isChecked={onboardPermissions?.viewFlows}
                            onChange={() =>
                              changeOnboardPermissions('viewFlows')
                            }
                          />
                          <Permission
                            permission="Create and edit flows"
                            isChecked={onboardPermissions?.manageFlows}
                            onChange={() =>
                              changeOnboardPermissions('manageFlows')
                            }
                          />

                          <Permission
                            permission="View verification logs"
                            isChecked={onboardPermissions?.viewVerifications}
                            onChange={() =>
                              changeOnboardPermissions('viewVerifications')
                            }
                          />

                          <Permission
                            permission="Export Verifications"
                            isChecked={onboardPermissions?.exportVerifications}
                            onChange={() =>
                              changeOnboardPermissions('exportVerifications')
                            }
                          />
                          <Permission
                            permission="Review Verifications"
                            isChecked={onboardPermissions?.reviewVerifications}
                            onChange={() =>
                              changeOnboardPermissions('reviewVerifications')
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {easy_detect_services && (
                  <div className="rounded p-4 bg-white">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Checkbox
                          noLeftPadding
                          isChecked={allDetectPermissions}
                          onChange={() => toggleDetectPermissions()}
                        />
                        <span className="text-xs font-medium uppercase text-grey">
                          Easy Detect
                        </span>
                      </div>
                      <div className="py-4 mt-2">
                        <div className="flex flex-col gap-4">
                          <Permission
                            permission="Manage flows"
                            isChecked={detectPermissions?.manageFlows}
                            onChange={() =>
                              changeDetectPermissions('manageFlows')
                            }
                          />
                          <Permission
                            permission="Edit Process"
                            isChecked={detectPermissions?.editProcess}
                            onChange={() =>
                              changeDetectPermissions('editProcess')
                            }
                          />
                          <Permission
                            permission="Review pending cases"
                            isChecked={detectPermissions?.reviewCases}
                            onChange={() =>
                              changeDetectPermissions('reviewCases')
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-4">
                {easy_lookup_services && (
                  <div className="rounded p-4 bg-white">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Checkbox
                          noLeftPadding
                          isChecked={allLookupPermissions}
                          onChange={() => {
                            const newOption = !allLookupPermissions;
                            toggleLookupPermissions(newOption);
                            toggleIndividualLookup(newOption);
                            toggleBusinessLookup(newOption);
                          }}
                        />
                        <span className="text-xs font-medium uppercase text-grey">
                          Government Lookup
                        </span>
                      </div>
                      <div className="mt-6">
                        <div className="flex items-center">
                          <Checkbox
                            noLeftPadding
                            isChecked={individualLookup}
                            onChange={() =>
                              toggleIndividualLookup(!individualLookup)
                            }
                          />
                          <span className="text-xs font-medium uppercase text-grey">
                            Individual Lookup
                          </span>
                        </div>

                        <div className="flex flex-col gap-4 pl-5 mt-4">
                          <Permission
                            permission="Perform Individual single lookup"
                            isChecked={
                              lookupPermissions?.performSingleIndividualLookup
                            }
                            onChange={() =>
                              changeLookupPermissions(
                                'performSingleIndividualLookup',
                              )
                            }
                          />
                          <Permission
                            permission="View Individual single history table"
                            isChecked={
                              lookupPermissions?.viewSingleIndividualLookup
                            }
                            onChange={() =>
                              changeLookupPermissions(
                                'viewSingleIndividualLookup',
                              )
                            }
                          />
                          <Permission
                            permission="Export Individual single lookup"
                            isChecked={
                              lookupPermissions?.downloadIndividualLookup
                            }
                            onChange={() =>
                              changeLookupPermissions(
                                'downloadIndividualLookup',
                              )
                            }
                            // disabled={
                            //   !lookupPermissions.performIndividualLookupAndViewHistory
                            // }
                          />
                          <Permission
                            permission="Perform Individual batch lookup"
                            isChecked={
                              lookupPermissions?.performBatchIndividualLookup
                            }
                            onChange={() =>
                              changeLookupPermissions(
                                'performBatchIndividualLookup',
                              )
                            }
                            // disabled={
                            //   !lookupPermissions.performBatchLookupAndViewHistory
                            // }
                          />
                          <Permission
                            permission="View Individual batch history table"
                            isChecked={
                              lookupPermissions?.viewBatchIndividualLookup
                            }
                            onChange={() =>
                              changeLookupPermissions(
                                'viewBatchIndividualLookup',
                              )
                            }
                            // disabled={
                            //   !lookupPermissions.performBatchLookupAndViewHistory
                            // }
                          />
                          <Permission
                            permission="Export Individual batch lookup"
                            isChecked={
                              lookupPermissions?.downloadIndividualLookup
                            }
                            onChange={() =>
                              changeLookupPermissions(
                                'downloadIndividualLookup',
                              )
                            }
                            // disabled={!lookupPermissions.downloadIndividualLookup}
                          />
                        </div>
                      </div>
                      <div className="mt-6">
                        <div className="flex items-center">
                          <Checkbox
                            noLeftPadding
                            isChecked={businessLookup}
                            onChange={() =>
                              toggleBusinessLookup(!businessLookup)
                            }
                          />
                          <span className="text-xs font-medium uppercase text-grey">
                            Business Lookup
                          </span>
                        </div>

                        <div className="flex flex-col gap-4 pl-5 mt-4">
                          <Permission
                            permission="Perform Business single lookup"
                            isChecked={
                              lookupPermissions?.performSingleBusinessLookup
                            }
                            onChange={() =>
                              changeLookupPermissions(
                                'performSingleBusinessLookup',
                              )
                            }
                          />
                          <Permission
                            permission="View Business single history table"
                            isChecked={
                              lookupPermissions?.viewSingleBusinessLookup
                            }
                            onChange={() =>
                              changeLookupPermissions(
                                'viewSingleBusinessLookup',
                              )
                            }
                          />
                          <Permission
                            permission="Export Business single history table"
                            isChecked={
                              lookupPermissions?.downloadBusinessLookup
                            }
                            onChange={() =>
                              changeLookupPermissions('downloadBusinessLookup')
                            }
                            // disabled={
                            //   !lookupPermissions.performSingleBusinessLookup
                            // }
                          />
                          <Permission
                            permission="Perform Business Batch lookup"
                            isChecked={
                              lookupPermissions?.performBatchBusinessLookup
                            }
                            onChange={() =>
                              changeLookupPermissions(
                                'performBatchBusinessLookup',
                              )
                            }
                            // disabled={
                            //   !lookupPermissions.performBatchLookupAndViewHistory
                            // }
                          />
                          <Permission
                            permission="View Business Batch history table"
                            isChecked={
                              lookupPermissions?.viewBatchBusinessLookup
                            }
                            onChange={() =>
                              changeLookupPermissions('viewBatchBusinessLookup')
                            }
                            // disabled={
                            //   !lookupPermissions.performBatchLookupAndViewHistory
                            // }
                          />
                          <Permission
                            permission="Export Business Batch history table"
                            isChecked={
                              lookupPermissions?.downloadBusinessLookup
                            }
                            onChange={() =>
                              changeLookupPermissions('downloadBusinessLookup')
                            }
                            // disabled={
                            //   !lookupPermissions.performIndividualLookupAndViewHistory
                            // }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {billings_services && (
                  <div className="rounded p-4 bg-white">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Checkbox
                          noLeftPadding
                          isChecked={allBillingPermissions}
                          onChange={() => toggleBillingPermissions()}
                        />
                        <span className="text-xs font-medium uppercase text-grey">
                          Billings
                        </span>
                      </div>
                      <div className="py-4 mt-2">
                        <div className="flex flex-col gap-4">
                          <Permission
                            permission="Top up"
                            isChecked={billingPermissions?.topup}
                            onChange={() => changeBillingPermissions('topup')}
                          />
                          <Permission
                            permission="Manage Threshold"
                            isChecked={billingPermissions?.manageThreshold}
                            onChange={() =>
                              changeBillingPermissions('manageThreshold')
                            }
                          />
                          <Permission
                            permission="View transaction history"
                            isChecked={billingPermissions?.viewHistory}
                            onChange={() =>
                              changeBillingPermissions('viewHistory')
                            }
                          />
                          <Permission
                            permission="Export transaction history"
                            isChecked={billingPermissions?.exportHistory}
                            onChange={() =>
                              changeBillingPermissions('exportHistory')
                            }
                          />
                          <Permission
                            permission="View API calls"
                            isChecked={billingPermissions?.viewLogs}
                            onChange={() =>
                              changeBillingPermissions('viewLogs')
                            }
                          />
                          <Permission
                            permission="Export API calls"
                            isChecked={billingPermissions?.exportLogs}
                            onChange={() =>
                              changeBillingPermissions('exportLogs')
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-4">
                {address_verification_services && (
                  <div className="rounded p-4 bg-white">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Checkbox
                          noLeftPadding
                          isChecked={allAddressVerificationPermissions}
                          onChange={() =>
                            toggleAddressVerificationPermissions()
                          }
                        />
                        <span className="text-xs font-medium uppercase text-grey">
                          Address verifications
                        </span>
                      </div>
                      <div className="py-4 mt-2">
                        <div className="flex flex-col gap-4">
                          <Permission
                            permission="Perform submit individual address"
                            isChecked={
                              addressVerificationPermissions?.performIndividualLookupAndViewHistory
                            }
                            onChange={() =>
                              changeAddressVerificationPermissions(
                                'performIndividualLookupAndViewHistory',
                              )
                            }
                          />
                          <Permission
                            permission="Export individual address"
                            isChecked={
                              addressVerificationPermissions?.downloadIndividualHistory
                            }
                            onChange={() =>
                              changeAddressVerificationPermissions(
                                'downloadIndividualHistory',
                              )
                            }
                          />
                          <Permission
                            permission="Perform submit batch address"
                            isChecked={
                              addressVerificationPermissions?.performBatchLookupAndViewHistory
                            }
                            onChange={() =>
                              changeAddressVerificationPermissions(
                                'performBatchLookupAndViewHistory',
                              )
                            }
                          />
                          <Permission
                            permission="Export batch address"
                            isChecked={
                              addressVerificationPermissions?.downloadBatchHistory
                            }
                            onChange={() =>
                              changeAddressVerificationPermissions(
                                'downloadBatchHistory',
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="rounded p-4 bg-white">
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <Checkbox
                        noLeftPadding
                        isChecked={allAnalyticsPermissions}
                        onChange={() => {
                          const newOption = !allAnalyticsPermissions;
                          toggleAnalyticsPermissions(newOption);
                          toggleApiReport(newOption);
                          toggleMessagingLogs(newOption);
                          toggleDetectReport(newOption);
                        }}
                      />
                      <span className="text-xs font-medium uppercase text-grey">
                        Report Analytics
                      </span>
                    </div>
                    {api_report_services && (
                      <div className="mt-6">
                        <div className="flex items-center">
                          <Checkbox
                            noLeftPadding
                            isChecked={apiReport}
                            onChange={() => toggleApiReport(!apiReport)}
                          />
                          <span className="text-xs font-medium uppercase text-grey">
                            Api Report
                          </span>
                        </div>
                        <div className="flex flex-col gap-4 pl-5 mt-4">
                          <Permission
                            permission="View API report"
                            isChecked={analyticsPermissions?.viewApi}
                            onChange={() =>
                              changeAnalyticsPermissions('viewApi')
                            }
                          />
                          <Permission
                            permission="Export API report"
                            isChecked={analyticsPermissions?.exportApi}
                            onChange={() =>
                              changeAnalyticsPermissions('exportApi')
                            }
                          />
                        </div>
                      </div>
                    )}

                    {messaging_report_services && (
                      <div className="mt-6">
                        <div className="flex items-center">
                          <Checkbox
                            noLeftPadding
                            isChecked={messagingLogs}
                            onChange={() => toggleMessagingLogs(!messagingLogs)}
                          />
                          <span className="text-xs font-medium uppercase text-grey">
                            Messaging Logs
                          </span>
                        </div>
                        <div className="flex flex-col gap-4 pl-5 mt-4">
                          <Permission
                            permission="View messaging logs"
                            isChecked={analyticsPermissions?.viewMessaging}
                            onChange={() =>
                              changeAnalyticsPermissions('viewMessaging')
                            }
                          />
                          <Permission
                            permission="Export messaging logs"
                            isChecked={analyticsPermissions?.exportMessaging}
                            onChange={() =>
                              changeAnalyticsPermissions('exportMessaging')
                            }
                          />
                        </div>
                      </div>
                    )}

                    {easy_detect_report_services && (
                      <div className="mt-6">
                        <div className="flex items-center">
                          <Checkbox
                            noLeftPadding
                            isChecked={detectReport}
                            onChange={() => toggleDetectReport(!detectReport)}
                          />
                          <span className="text-xs font-medium uppercase text-grey">
                            Easy Detect Report
                          </span>
                        </div>
                        <div className="flex flex-col gap-4 pl-5 mt-4">
                          <Permission
                            permission="View Easy Detect report"
                            isChecked={analyticsPermissions?.viewEasyDetect}
                            onChange={() =>
                              changeAnalyticsPermissions('viewEasyDetect')
                            }
                          />
                          <Permission
                            permission="Export easy detect"
                            isChecked={analyticsPermissions?.exportEasyDetect}
                            onChange={() =>
                              changeAnalyticsPermissions('exportEasyDetect')
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {baas && (
                  <div className="rounded p-4 bg-white">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Checkbox
                          noLeftPadding
                          isChecked={allBaasPermissions}
                          onChange={() => toggleBAASPermissions()}
                        />
                        <span className="text-xs font-medium uppercase text-grey">
                          BAAS
                        </span>
                      </div>
                      <div className="py-4 mt-2">
                        <div className="flex flex-col gap-4">
                          <Permission
                            permission="Overview"
                            isChecked={baasPermissions?.overview}
                            onChange={() => changeBaasPermissions('overview')}
                          />
                          <Permission
                            permission="Can Transfer"
                            isChecked={baasPermissions?.transfer}
                            onChange={() => changeBaasPermissions('transfer')}
                          />
                          <Permission
                            permission="Can Payout"
                            isChecked={baasPermissions?.payout}
                            onChange={() => changeBaasPermissions('payout')}
                          />
                          <Permission
                            permission="View Transactions"
                            isChecked={baasPermissions?.viewTransactions}
                            onChange={() =>
                              changeBaasPermissions('viewTransactions')
                            }
                          />
                          <Permission
                            permission="View Wallets history"
                            isChecked={baasPermissions?.viewWallets}
                            onChange={() =>
                              changeBaasPermissions('viewWallets')
                            }
                          />{' '}
                          <Permission
                            permission="View Payout history"
                            isChecked={baasPermissions?.viewPayouts}
                            onChange={() =>
                              changeBaasPermissions('viewPayouts')
                            }
                          />
                          <Permission
                            permission="Export transaction history"
                            isChecked={baasPermissions?.exportTransactions}
                            onChange={() =>
                              changeBaasPermissions('exportTransactions')
                            }
                          />
                          <Permission
                            permission="Export Payouts history"
                            isChecked={baasPermissions?.exportPayouts}
                            onChange={() =>
                              changeBaasPermissions('exportPayouts')
                            }
                          />
                          <Permission
                            permission="Export Wallets history"
                            isChecked={baasPermissions?.exportWallets}
                            onChange={() =>
                              changeBaasPermissions('exportWallets')
                            }
                          />{' '}
                          <Permission
                            permission="Manage Settings"
                            isChecked={baasPermissions?.manageSettings}
                            onChange={() =>
                              changeBaasPermissions('manageSettings')
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-4">
                {document_analysis && (
                  <div className="rounded p-4 bg-white">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Checkbox
                          noLeftPadding
                          isChecked={allDocumentAnalysisPermissions}
                          onChange={() => toggleDocumentAnalysisPermissions()}
                        />
                        <span className="text-xs font-medium uppercase text-grey">
                          Document analysis
                        </span>
                      </div>
                      <div className="py-4 mt-2">
                        <div className="flex flex-col gap-4">
                          <Permission
                            permission="Perform single analysis"
                            isChecked={
                              documentAnalysisPermissions?.performSingleLookup
                            }
                            onChange={() =>
                              changeDocumentAnalysisPermissions(
                                'performSingleLookup',
                              )
                            }
                          />
                          <Permission
                            permission="View single analysis history"
                            isChecked={
                              documentAnalysisPermissions?.viewSingleLookup
                            }
                            onChange={() =>
                              changeDocumentAnalysisPermissions(
                                'viewSingleLookup',
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="rounded p-4 bg-white">
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <Checkbox
                        noLeftPadding
                        isChecked={allSettingsPermissions}
                        onChange={() => toggleSettingsPermissions()}
                      />
                      <span className="text-xs font-medium uppercase text-grey">
                        Settings
                      </span>
                    </div>
                    <div className="py-4 mt-2">
                      <div className="flex flex-col gap-4">
                        <Permission
                          permission="Manage roles and permission"
                          isChecked={settingsPermissions?.managePermissions}
                          onChange={() =>
                            changeSettingsPermissions('managePermissions')
                          }
                        />
                        {teams_services && (
                          <Permission
                            permission="Manage members - invite, edit, and delete"
                            isChecked={settingsPermissions?.manageMembers}
                            onChange={() =>
                              changeSettingsPermissions('manageMembers')
                            }
                          />
                        )}

                        {audit_logs_services && (
                          <Permission
                            permission="View and download Audit logs"
                            isChecked={
                              settingsPermissions?.viewAndDownloadAudit
                            }
                            onChange={() =>
                              changeSettingsPermissions('viewAndDownloadAudit')
                            }
                            disabled={!settingsPermissions.viewAndDownloadAudit}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {developers_services && (
                  <div className="rounded p-4 bg-white">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Checkbox
                          noLeftPadding
                          isChecked={allDeveloperPermissions}
                          onChange={() => toggleDeveloperPermissions()}
                        />
                        <span className="text-xs font-medium uppercase text-grey">
                          Developers
                        </span>
                      </div>
                      <div className="py-4 mt-2">
                        <div className="flex flex-col gap-4">
                          <Permission
                            permission="Manage Apps - Create, edit and delete apps"
                            isChecked={developerPermissions?.manageApps}
                            onChange={() =>
                              changeDeveloperPermissions('manageApps')
                            }
                          />
                          <Permission
                            permission="Re-generate keys"
                            isChecked={developerPermissions?.regenerateKeys}
                            onChange={() =>
                              changeDeveloperPermissions('regenerateKeys')
                            }
                          />
                          <Permission
                            permission="View live keys"
                            isChecked={developerPermissions?.viewLiveKeys}
                            onChange={() =>
                              changeDeveloperPermissions('viewLiveKeys')
                            }
                          />
                          {api_logs_services && (
                            <Permission
                              permission="View and export API logs"
                              isChecked={
                                developerPermissions?.viewAndExportLogs
                              }
                              onChange={() =>
                                changeDeveloperPermissions('viewAndExportLogs')
                              }
                            />
                          )}

                          <Permission
                            permission="Create, Edit and delete API tokens"
                            isChecked={developerPermissions?.manageTokens}
                            onChange={() =>
                              changeDeveloperPermissions('manageTokens')
                            }
                          />
                          <Permission
                            permission="Create and edit Webhooks"
                            isChecked={developerPermissions?.manageWebhooks}
                            onChange={() =>
                              changeDeveloperPermissions('manageWebhooks')
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {aml_screening_services && (
                  <div className="rounded p-4 bg-white">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Checkbox
                          noLeftPadding
                          isChecked={allAmlScreeningPermissions}
                          onChange={() => {
                            const newOption = !allAmlScreeningPermissions;
                            toggleAmlScreeningPermissions(newOption);
                            toggleUserScreening(newOption);
                            toggleBusinessScreening(newOption);
                          }}
                        />

                        <span className="text-xs font-medium uppercase text-grey">
                          AML screening
                        </span>
                      </div>
                      <div className="mt-6">
                        <div className="flex items-center">
                          <Checkbox
                            noLeftPadding
                            isChecked={userScreening}
                            onChange={() => toggleUserScreening(!userScreening)}
                          />
                          <span className="text-xs font-medium uppercase text-grey">
                            User Screening
                          </span>
                        </div>
                        <div className="flex flex-col gap-4 pl-5 mt-4">
                          <Permission
                            permission="Perform single user screening"
                            isChecked={
                              amlScreeningPermissions?.performSingleIndividualLookup
                            }
                            onChange={() =>
                              changeAmlScreeningPermissions(
                                'performSingleIndividualLookup',
                              )
                            }
                          />
                          <Permission
                            permission="View single user screening lookup history"
                            isChecked={
                              amlScreeningPermissions?.viewSingleIndividualLookup
                            }
                            onChange={() =>
                              changeAmlScreeningPermissions(
                                'viewSingleIndividualLookup',
                              )
                            }
                          />
                          <Permission
                            permission="Perform batch user screening"
                            isChecked={
                              amlScreeningPermissions?.performBatchIndividualLookup
                            }
                            onChange={() =>
                              changeAmlScreeningPermissions(
                                'performBatchIndividualLookup',
                              )
                            }
                          />{' '}
                          <Permission
                            permission="View batch user screening lookup history"
                            isChecked={
                              amlScreeningPermissions?.viewBatchIndividualLookup
                            }
                            onChange={() =>
                              changeAmlScreeningPermissions(
                                'viewBatchIndividualLookup',
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="mt-6">
                        <div className="flex items-center">
                          <Checkbox
                            noLeftPadding
                            isChecked={businessScreening}
                            onChange={() =>
                              toggleBusinessScreening(!businessScreening)
                            }
                          />
                          <span className="text-xs font-medium uppercase text-grey">
                            Business screening
                          </span>
                        </div>
                        <div className="flex flex-col gap-4 pl-5 mt-4">
                          <div className="flex flex-col gap-4">
                            <Permission
                              permission="Perform single business screening"
                              isChecked={
                                amlScreeningPermissions?.performSingleBusinessLookup
                              }
                              onChange={() =>
                                changeAmlScreeningPermissions(
                                  'performSingleBusinessLookup',
                                )
                              }
                            />
                            <Permission
                              permission="View Business single screening lookup history"
                              isChecked={
                                amlScreeningPermissions?.viewSingleBusinessLookup
                              }
                              onChange={() =>
                                changeAmlScreeningPermissions(
                                  'viewSingleBusinessLookup',
                                )
                              }
                            />
                            <Permission
                              permission="Perform batch business screening"
                              isChecked={
                                amlScreeningPermissions?.performBatchBusinessLookup
                              }
                              onChange={() =>
                                changeAmlScreeningPermissions(
                                  'performBatchBusinessLookup',
                                )
                              }
                            />{' '}
                            <Permission
                              permission="View Business batch screening lookup history"
                              isChecked={
                                amlScreeningPermissions?.viewBatchBusinessLookup
                              }
                              onChange={() =>
                                changeAmlScreeningPermissions(
                                  'viewBatchBusinessLookup',
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        children
      )}
    </DashboardLayout>
  );
};

export default connect(state => state, Actions)(SettingsTeamLayout);
