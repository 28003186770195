import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useQuery} from 'react-query';
import {DashboardLayout, SkeletonLoader} from '../../../components';
import {
  baasBalance,
  baasMobile,
  baasTotalInflow,
  baasTotalOutflow,
  baasTotalRevenue,
} from '../../../assets/images/images';
import BaasLineChart from './BaasLineChart';
import {useAllApps} from '../../../hooks/useApps';
import {
  fetchBAASBreakdown,
  fetchBAASChart,
} from '../../../requests/queries/baas';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';
import {Period} from '../Reconciliation/components/Period';
import {verificationChartPeriod} from '../Reconciliation/constants';
import {CreatePinModal} from './components/CreatePinModal';
import {toast} from 'react-toastify';
import {useBaasSettlement} from './hooks/useBaasSettlement';
import {CopyItem} from '../../../components/CopyItem';

const StatCard = ({imgSrc, title, value, isLoading}) => (
  <div className="bg-white rounded-lg flex items-center gap-2 px-6 py-8">
    <img src={imgSrc} alt={title} className="mb-3" />
    <div className="flex flex-col">
      <p className="text-body font-medium text-sm">{title}</p>
      {isLoading ? (
        <SkeletonLoader style={{width: 150, height: 30, marginBottom: 10}} />
      ) : (
        <p
          className={`font-semibold text-[28px] tracking-[-0.02em] ${
            value < 0 ? 'text-status-404' : 'text-grey'
          }`}
        >
          {shortenLargeNumbers(value || 0, 'NGN', {
            startAt: 'M',
          })}
        </p>
      )}
    </div>
  </div>
);

function Index() {
  const [createPin, setCreatePin] = useState(false);
  const [date, setDate] = useState('monthly');

  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const {userDetails} = useSelector(state => state?.auth);
  const {userApps, isLoading: appsLoading} = useAllApps();
  const {user_account, isSettlementLoading, isFetching} = useBaasSettlement();

  const appIds = useMemo(
    () => (!appsLoading && userApps.length > 0 ? userApps[0]?.value : ''),
    [appsLoading, userApps],
  );

  const params = useMemo(
    () => ({
      company_id: userDetails && userDetails?.company?.id,
      ...(appIds && {app_id: appIds}),
    }),
    [appIds, userDetails],
  );
  const chartsParams = useMemo(
    () => ({
      company_id: userDetails && userDetails?.company?.id,
      period: date,
    }),
    [date, userDetails],
  );
  const {data: breakdown, isLoading: isBreakdownLoading} = useQuery(
    ['baas-breakdown', {params}],
    () => fetchBAASBreakdown({params}),
  );

  const {data: charts, isLoading: isChartsLoading} = useQuery(
    ['baas-charts', {params: {...chartsParams}}],
    () => fetchBAASChart({...chartsParams}),
    {
      meta: {preventGlobalError: true},
    },
  );

  const ANALYSIS_DATA = useMemo(
    () => (!isChartsLoading && charts ? charts?.entity : []),
    [charts, isChartsLoading],
  );

  const account_breakdown = useMemo(
    () => !isBreakdownLoading && breakdown && breakdown?.entity,
    [isBreakdownLoading, breakdown],
  );
  const userAccountDetails = useMemo(
    () => ({
      bank_name: user_account?.bank_name,
      account_number: user_account?.account_number,
      account_name: user_account?.account_name,
    }),
    [user_account],
  );

  const stats = [
    {
      imgSrc: baasTotalRevenue,
      title: 'Total NetfLow',
      value: account_breakdown?.aggregate,
    },
    {
      imgSrc: baasTotalInflow,
      title: 'Total Inflow',
      value: account_breakdown?.total_deposit,
    },
    {
      imgSrc: baasTotalOutflow,
      title: 'Total Outflow',
      value: account_breakdown?.total_transfer,
    },
  ];

  useEffect(() => {
    if (!isSettlementLoading && !isFetching && user_account) {
      setCreatePin(!user_account?.has_pin);
    }
  }, [isFetching, isSettlementLoading, user_account?.has_pin, user_account]);

  return (
    <DashboardLayout
      bg="bg-white80"
      topPadding="pt-8"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={baasMobile} alt="Mobile" />
          <p className="ml-2 -mb-1">Overview</p>
        </div>
      }
    >
      <div className="flex gap-5 flex-col lg:flex-row">
        <div className="bg-white w-full lg:w-[30%] rounded-lg flex flex-col lg:flex-row gap-4 lg:gap-0 lg:items-center justify-between px-2 lg:px-6 py-10">
          <div className="flex items-center gap-2">
            <img src={baasBalance} alt="Balance" className="mb-3" />
            <div className="flex flex-col">
              <p className="text-body font-medium text-sm">Available Balance</p>
              {isSettlementLoading ? (
                <SkeletonLoader
                  style={{
                    width: 150,
                    height: 25,
                    marginBottom: 10,
                  }}
                />
              ) : (
                <>
                  <p className="font-semibold text-[26px] tracking-[-0.02em] text-grey">
                    {shortenLargeNumbers(user_account?.amount || 0, 'NGN', {
                      startAt: 'B',
                    })}
                  </p>
                  <div className="flex mt-2 flex-col">
                    <p className="text-body font-medium text-sm">
                      Ledger Balance <br />
                      <span className="font-semibold text-[16px] tracking-[-0.02em] text-grey ">
                        {shortenLargeNumbers(
                          user_account?.ledger_balance || 0,
                          'NGN',
                          {
                            startAt: 'B',
                          },
                        )}
                      </span>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="bg-white w-full lg:w-[70%] rounded-lg flex flex-col lg:flex-row gap-4 lg:gap-0 lg:items-center justify-between px-2 lg:px-6 py-10">
          <div className="flex items-start justify-start w-full flex-col gap-5 lg:justify-around lg:flex-row lg:gap-0 ">
            {Object.entries(userAccountDetails || {})?.map(([key, value]) => (
              <div key={key} className="flex flex-col">
                <p className="text-body font-medium text-sm capitalize">
                  {key?.replaceAll('_', ' ')}
                </p>
                {isSettlementLoading || appsLoading ? (
                  <SkeletonLoader
                    style={{
                      width: 150 * Math.random() + key?.length,
                      height: 25,
                      marginBottom: 10,
                    }}
                  />
                ) : (
                  <p
                    className={`${
                      !value ? '' : 'font-semibold'
                    } text-[18px] tracking-[-0.02em] text-grey flex items-center gap-1`}
                  >
                    {value || 'N/A'}

                    {value && key === 'account_number' && (
                      <CopyItem value={value} />
                    )}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <CreatePinModal
        onClose={() => {
          if (!user_account?.has_pin) {
            return toast.error(
              'Please create a Transaction PIN before you proceed.',
            );
          }
          setCreatePin(!createPin);
        }}
        show={createPin}
      />

      {/*<div className="my-4 flex w-full justify-end">*/}
      {/*  <FilterButton*/}
      {/*    openFilter={openFilter}*/}
      {/*    setOpenFilter={setOpenFilter}*/}
      {/*    className="h-[48px] justify-center w-[130px]"*/}
      {/*  />*/}
      {/*</div>*/}

      <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 w-full gap-4">
        {stats.map((stat, index) => (
          <StatCard
            key={index}
            imgSrc={stat.imgSrc}
            title={stat.title}
            value={stat.value}
            isLoading={isBreakdownLoading}
          />
        ))}
      </div>

      <div className="bg-white rounded-xl w-full mt-8 h-[444px] font-inter">
        <div className="px-4 relative">
          <div
            className={`flex absolute top-[-12px] lg:top-3 z-10 flex-col right-[-12px] lg:right-3 lg:flex-row gap-5 lg:gap-0 justify-between items-center   px-6`}
          >
            <Period
              date={date}
              setDate={setDate}
              datePeriod={queryParams.get('period')}
              otherChartPeriod={verificationChartPeriod}
            />
          </div>
        </div>
        <BaasLineChart
          data={ANALYSIS_DATA}
          periodType={date}
          loading={isChartsLoading}
        />
      </div>
    </DashboardLayout>
  );
}

export default Index;
