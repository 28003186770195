import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useSelector} from 'react-redux';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useFlowOverview} from './useOverview';
import {createFlow, fetchFlows} from '../../../../requests/queries/flows';
import {toast} from 'react-toastify';
import {PageContext} from '../../../../helpers/context';
import {parseUrlParams} from '../../../../helpers';

export const useFlowsLogic = () => {
  const initialRender = useRef(true);
  const {userDetails} = useSelector(state => state?.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const queryClient = useQueryClient();

  const params = useMemo(
    () => ({
      company_id: userDetails?.company?.id,
      limit,
      page: currentPage,
    }),
    [currentPage, limit, userDetails?.company?.id],
  );
  const {mutate: createFlowMutation} = useMutation(
    'create-e-flow',
    createFlow,
    {
      onSuccess: () => {
        toast.success('A Default flow has been created successfully');
        queryClient.invalidateQueries();
      },
      onError: error => {
        toast.error(error?.response?.data?.error);
      },
    },
  );

  const addDefaultFlow = useCallback(async () => {
    try {
      const data = {
        company_id: userDetails && userDetails?.company?.id,
        name: 'Default Flow',
        desc: 'Default Flow Description',
        usecase: 'bank transaction',
      };

      await createFlowMutation(data);
    } catch (e) {
      console.log(e);
    }
  }, [createFlowMutation, userDetails]);

  const {data: easyFlows, isLoading} = useQuery(
    ['e-flows', {params}],
    () => fetchFlows({params}),
    {
      onError: err => {
        // toast.error(err.response.data.error);
        if (err.response.data.error === 'Company does not Exist') {
          addDefaultFlow();
        }
      },
    },
  );

  useEffect(() => {
    if (!isLoading && easyFlows?.rows?.length === 0 && initialRender.current) {
      addDefaultFlow();
      initialRender.current = false;
    }
  }, [addDefaultFlow, initialRender, easyFlows?.rows?.length, isLoading]);

  const flows = useMemo(
    () => !isLoading && easyFlows && {flows: easyFlows?.rows},
    [easyFlows, isLoading],
  );

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const {
    TOTAL_EVENTS,
    TOTAL_BLOCKED,
    TOTAL_ALLOWED,
    TOTAL_PENDING,
    isLoading: overviewLoading,
  } = useFlowOverview({enabled: !isLoading && easyFlows?.rows?.length > 0});

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(parseInt(pageParam));
      apiParams.page = parseInt(pageParam);
    } else {
      apiParams.page = currentPage;
    }
  }, [currentPage, limit]);

  const {manageFlows} =
    useMemo(() => userDetails?.permissions?.detectPermissions, [userDetails]) ||
    {};
  return {
    pageClick,
    manageFlows,
    flows,
    TOTAL_EVENTS,
    TOTAL_BLOCKED,
    TOTAL_ALLOWED,
    TOTAL_PENDING,
    isLoading,
    setLimit,
    overviewLoading,
    easyFlows,
    limit,
    currentPage,
    companyId: userDetails?.company?.id,
  };
};
