import React, {useMemo} from 'react';
import {Modal, PrimaryButton, SkeletonLoader} from '../../../components';
import {closeModal} from '../../../assets/images/images';
import {useQuery} from 'react-query';
import {fetchBAASTransactionPayout} from '../../../requests/queries/baas';
import moment from 'moment';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';
import {getStatusTextAndStyles} from '../../../helpers/getStatusTextAndStyles';
import {getStatusColor} from '../../../helpers';
import {useBaasReceiptDownload} from './hooks/useBaasReceiptDownload';

function BaasPayoutModal({show, onClose, transaction}) {
  const params = useMemo(
    () => ({
      settlement_id: (transaction && transaction?.settlement_id) || '',
    }),
    [transaction],
  );

  const {data: settlement_details, isLoading} = useQuery(
    ['baas-payout-details', {params}],
    () => fetchBAASTransactionPayout({params}),
    {
      enabled: !!(transaction && transaction?.settlement_id),
    },
  );
  const {handleDownload, downloadLoading} = useBaasReceiptDownload({
    params,
    apiFunc: fetchBAASTransactionPayout,
  });

  const settlementDetailsData = useMemo(
    () => !isLoading && settlement_details && settlement_details?.entity,
    [isLoading, settlement_details],
  );

  const receiverDetails = [
    {
      label: 'Account Name',
      value: settlementDetailsData?.account_name || '-',
    },
    {
      label: 'Bank Name',
      value: settlementDetailsData?.bank || '-',
    },
    {
      label: 'Account Number',
      value: settlementDetailsData?.account_number || '-',
    },
  ];

  const transactionType =
    settlementDetailsData?.transaction_type &&
    settlementDetailsData?.transaction_type !== 'transfer'
      ? 'credit'
      : 'debit';

  const transactionDetails = [
    {
      label: 'Transaction Time',
      value:
        settlementDetailsData?.date_created &&
        moment(settlementDetailsData?.date_created).format('DD MMM YYYY h:m'),
    },
    {
      label: 'Transaction Amount',
      value: shortenLargeNumbers(
        settlementDetailsData?.transaction_amount,
        'NGN',
        {startAt: 'B'},
      ),
    },
    {
      label: 'Currency',
      value: 'NGN',
    },
    {
      label: 'Type',
      value: (
        <span
          className={`uppercase w-fit  h-5 flex items-center justify-center font-medium text-xs rounded-full px-2 py-1 ${getStatusColor(
            transactionType,
          )}`}
        >
          {transactionType}
        </span>
      ),
    },
    {
      label: 'Status',
      value: (
        <span
          className={`uppercase w-fit rounded-full px-2 py-1 h-5 font-inter flex items-center justify-center  text-xs ${
            getStatusTextAndStyles(settlementDetailsData?.transaction_status)
              ?.solid
          }`}
        >
          {
            getStatusTextAndStyles(settlementDetailsData?.transaction_status)
              ?.text
          }
        </span>
      ),
    },
  ];

  return (
    <Modal
      show={show}
      onClose={onClose}
      extraModalClasses="max-w-[579px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          <h3 className="font-medium text-base text-grey">
            Transaction Details
          </h3>

          <button
            onClick={() => {
              onClose(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>

        <div className="mt-6">
          <p className="text-base font-medium text-[#727272] text-left">
            Account Details
          </p>
          <div className="mt-2 p-6 rounded-xl bg-white80">
            {receiverDetails.map(({label, value}, index) => (
              <div key={label}>
                <div className="flex items-center justify-between relative">
                  <p className="text-tiny text-[#868C98]">{label}</p>
                  {isLoading ? (
                    <SkeletonLoader
                      style={{
                        width: 150 * Math.random(index + 1),
                        height: 20,
                        position: 'absolute',
                        top: 0,
                        right: 0,
                      }}
                    />
                  ) : (
                    <p className="text-tiny text-black font-medium">{value}</p>
                  )}
                </div>
                {index !== receiverDetails.length - 1 && (
                  <hr className="my-4 text-[#E1E4EA]" />
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="mt-6">
          <p className="text-base font-medium text-[#727272] text-left">
            Transaction Details
          </p>
          <div className="mt-2 p-6 rounded-xl bg-white80">
            {transactionDetails.map(({label, value}, index) => (
              <div key={label}>
                <div className="flex items-center justify-between relative">
                  <p className="text-tiny text-[#868C98]">{label}</p>
                  {isLoading ? (
                    <SkeletonLoader
                      style={{
                        width: 150 * Math.random(index + 1),
                        height: 20,
                        position: 'absolute',
                        top: 0,
                        right: 0,
                      }}
                    />
                  ) : (
                    <p className="text-tiny text-black font-medium">{value}</p>
                  )}
                </div>
                {index !== transactionDetails.length - 1 && (
                  <hr className="my-4 text-[#E1E4EA]" />
                )}
              </div>
            ))}
          </div>
        </div>
        <PrimaryButton
          buttonText="Download Receipt"
          className="mt-6 w-full"
          onClick={handleDownload}
          loading={downloadLoading}
        />
      </div>
    </Modal>
  );
}

export default BaasPayoutModal;
