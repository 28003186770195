import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
  backNofill,
  chevronRight,
  configurationIc,
} from '../../assets/images/images';
import {DashboardLayout, SecondaryButton} from '../../components';
import * as Actions from '../../store/actions';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import {useNavigate} from 'react-router-dom';

function DevelopersWebhook({webhook: {portalUrl}}) {
  const [loading, setLoading] = useState(
    portalUrl === null ? true : Boolean(portalUrl?.entity?.portal_url),
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (loading && portalUrl === null) {
      setTimeout(() => setLoading(false), 3000);
    }
  }, [loading, portalUrl]);

  return (
    <DashboardLayout
      bg="bg-white"
      topPadding="pt-6"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={configurationIc} alt="" />
          <p className="ml-2 -mb-1">Developers</p>
          <img src={chevronRight} alt="" />
          <p className="-mb-1">Webhooks</p>
        </div>
      }
    >
      <section className="flex flex-col">
        <div className="flex items-center justify-between w-full mb-6">
          <h2 className="text-grey text-base sm:text-lg font-medium">
            Webhook Details
          </h2>
        </div>

        {loading ? <Loader /> : null}
        {portalUrl && Boolean(portalUrl?.entity?.portal_url) ? (
          <iframe
            src={`${portalUrl && portalUrl?.entity?.portal_url}` || ''}
            height={750}
            width="auto"
            onLoad={() => setLoading(false)}
            className="min-h-screen"
          />
        ) : (
          <>
            {!loading && (
              <EmptyState
                noBtn
                body="You do not have any subscription details to display"
                customBtn={
                  <SecondaryButton
                    onClick={() => navigate('/developers/webhooks')}
                    buttonText="Go back"
                    btnHeight="h-[24px]"
                    yPadding=""
                    src={backNofill}
                    leftImg
                    className="flex items-center pr-6"
                  />
                }
                height={64}
              />
            )}
          </>
        )}
      </section>
    </DashboardLayout>
  );
}
export default connect(state => state, Actions)(DevelopersWebhook);
