'use client';

import React, {useState} from 'react';
import {useQuery} from 'react-query';
import moment from 'moment';
import {Accordion, EmptyState, Loader} from '../../../components';
import Layout from '../Layout';
import {fetchBusinessID} from '../../../requests/queries/business360';
import {useLocation, useParams} from 'react-router-dom';
import {
  corruptedFile,
  downloadGrey,
  enlargeIc,
  nigeriaIc,
  southAfricaIc,
  placeholderLiveness,
} from '../../../assets/images/images';

const getCountryLogo = country =>
  ({
    nigeria: nigeriaIc,
    ng: nigeriaIc,
    'south-africa': southAfricaIc,
    sa: southAfricaIc,
  }[country?.toLowerCase() || 'nigeria']);

const ImageComponent = ({imageUrl}) => {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const toggleImageSize = () => {
    setIsEnlarged(prevState => !prevState);
  };

  return (
    <div className="relative w-full">
      <img
        src={imageUrl}
        alt=""
        width={600}
        height={isEnlarged ? 600 : 192}
        className={`mx-auto w-full ${
          isEnlarged ? 'h-full' : 'max-h-[192px]'
        } object-cover rounded-lg`}
      />
      {imageUrl && (
        <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex flex-col md:flex-row items-center justify-center gap-2">
          <div className="flex gap-2">
            <button
              className="flex items-start gap-2 p-2 text-xs font-medium rounded text-grey bg-white80"
              onClick={toggleImageSize}
            >
              <span className="lg:-mb-[2px]">
                {isEnlarged ? 'Shrink image' : 'Enlarge image'}
              </span>
              <img
                src={enlargeIc}
                alt=""
                width={16}
                height={16}
                className="-mt-[2px]"
              />
            </button>
          </div>
          <div className="flex gap-2">
            <a
              href={imageUrl}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-start gap-2 p-2 text-xs font-medium rounded text-grey bg-white80"
            >
              <span className="-mb-[2px]">Download image</span>
              <img
                src={downloadGrey}
                alt=""
                width={16}
                height={16}
                className="-mt-[2px]"
              />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default function BusinessId() {
  const {slug} = useParams();
  const location = useLocation();

  const entityTypes = location.state?.entityTypes || [];

  const {data: businessEntities, isLoading: entityLoading} = useQuery(
    ['business-id', {slug}],
    () => fetchBusinessID(slug),
  );

  const isEmpty = !businessEntities || businessEntities.data.length === 0;

  if (entityLoading) {
    return (
      <Layout>
        <Loader height={50} />
      </Layout>
    );
  }

  return (
    <Layout>
      {isEmpty ? (
        <div className="bg-white80 w-full">
          <EmptyState
            body="There is no information to display here at the moment"
            src={corruptedFile}
            height={45}
            imageHeight={48}
            imageWidth={48}
            bodyMaxWidth="max-w-[179px]"
          />
        </div>
      ) : (
        <div className="space-y-4">
          {businessEntities?.data.map((entity, index) => (
            <Accordion
              key={index}
              title={entityTypes[index].replace(/^NG_/, '').toUpperCase()}
              border
              customClasses="rounded-xl"
              customChildrenClasses="mt-2 border-t border-grey-50"
              customPadding="px-6 py-5"
              customComponent={
                <div className="flex items-center gap-2 rounded-full px-2 py-1 outline outline-1 outline-grey-50 bg-white80">
                  <p className="text-deepBlue text-xs font-medium uppercase -mb-1">
                    {entity.country}
                  </p>
                  <div className="w-5 h-5 flex items-center justify-center bg-blue-50 outline outline-1 outline-blue-100 rounded-full">
                    <img
                      src={getCountryLogo(entity.country)}
                      alt=""
                      width={12}
                      height={8}
                    />
                  </div>
                </div>
              }
              noChildPadding
              customTitleSize="text-lg"
              customTitleColor="text-grey"
            >
              <div className="pl-8 pr-10 pt-6 pb-6">
                <div className="ml-auto w-fit flex items-center gap-2 rounded-full px-2 py-1 bg-grey60 text-grey text-xs font-medium uppercase">
                  <span className="-mb-[2px]">
                    {entity.confidence
                      ? `${entity.confidence}% image match`
                      : 'Confidence not available'}
                  </span>
                </div>
                <div className="mt-6 flex items-center gap-14">
                  <ImageComponent
                    imageUrl={entity.image_url || placeholderLiveness}
                  />
                </div>

                <div className="mt-16 w-full inline-grid grid-cols-1 md:grid-cols-2 xl:grid-cols-[auto_auto_auto] items-start gap-10 justify-between">
                  {[
                    {
                      field_name: 'business name',
                      value: entity.business_name,
                    },
                    {
                      field_name: 'RC Number',
                      value: entity.extracted_rc_no,
                    },
                    {
                      field_name: 'GD Type',
                      value: entity.gd_type,
                    },
                    {
                      field_name: 'date',
                      value: entity.datetime
                        ? moment(entity.datetime).format('DD MMM YYYY HH:mm')
                        : 'N/A',
                    },
                  ].map((item, index) => (
                    <div key={index} className="flex flex-col gap-2">
                      <p className="text-grey-700 text-xs font-medium uppercase">
                        {item.field_name}
                      </p>
                      <p className="text-grey text-sm font-medium">
                        {item.value || 'N/A'}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </Accordion>
          ))}
        </div>
      )}
    </Layout>
  );
}
