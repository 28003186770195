import React from 'react';
import Layout from '../Layout';
import {EmptyState} from '../../../components';
import {corruptedFile} from '../../../assets/images/images';

export default function DirectorInfo() {
  const isEmpty = false;
  return (
    <Layout>
      {isEmpty ? (
        <div className="bg-white80 w-full">
          <EmptyState
            body="There is no information to display here at the moment"
            src={corruptedFile}
            height={45}
            imageHeight={48}
            imageWidth={48}
            bodyMaxWidth="max-w-[179px]"
            buttonText="Validate document"
          />
        </div>
      ) : (
        <></>
        // <Accordion
        //   title="Director’s info"
        //   border
        //   customClasses="rounded-xl"
        //   customComponent={
        //     <div className="flex items-center gap-2 rounded-full px-2 py-1 outline outline-1 outline-grey-50 bg-white80">
        //       <p className="text-deepBlue text-xs font-medium uppercase -mb-1">
        //         Nigeria
        //       </p>
        //       <div className="w-5 h-5 flex items-center justify-center bg-blue-50 outline outline-1 outline-blue-100 rounded-full">
        //         <img src={nigeriaIc} alt="" width={12} height={8} />
        //       </div>
        //     </div>
        //   }
        //   customChildrenClasses="mt-2 border-t border-grey-50"
        //   customPadding="px-6 py-5"
        //   noChildPadding
        //   customTitleSize="text-lg"
        //   customTitleColor="text-grey"
        // >
        //   <div className="w-full inline-grid grid-cols-[auto_auto_auto_auto] items-center gap-10 justify-between pl-8 pr-10 pt-6 pb-14">
        //     <img src={portraitPlaceholder} alt="Photo" width={89} height={89} />
        //     {BVN_DATA.map((item, index) => (
        //       <div key={index} className="flex flex-col gap-2">
        //         <p className="text-grey-700 text-xs font-medium uppercase">
        //           {item.field_name}
        //         </p>
        //         <p className="text-grey text-sm font-medium">{item.value}</p>
        //       </div>
        //     ))}
        //   </div>
        // </Accordion>
      )}
    </Layout>
  );
}
