import React, {useCallback} from 'react';
import {css} from '@emotion/react';

import {Modal, SecondaryButton} from '../../components';
import {closeModal} from '../../assets/images/images';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';
import PuffLoader from 'react-spinners/PuffLoader';

import {useMutation, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import {deleteWebhookSubscription} from '../../requests/queries/Developers/webbhook';

function DeleteModal({open, bodyText, params, setOpen}) {
  const queryClients = useQueryClient();

  const {mutate, isLoading} = useMutation(deleteWebhookSubscription);
  const handleModalDelete = useCallback(async () => {
    try {
      if (params) {
        await mutate(params, {
          onSuccess: () => {
            queryClients.invalidateQueries();
            setOpen(false);
          },
          onError: error => {
            toast.error(error?.response?.data?.error);
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [mutate, params, queryClients, setOpen]);

  const override = css`
    border-color: #ffffff;
    position: absolute;
    background: transparent;
  `;

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-grey text-base font-medium">Are you sure?</h3>
          <button onClick={() => setOpen(false)}>
            <img src={closeModal} alt="" />
          </button>
        </header>

        <p className="mt-4 text-sm text-body text-start">{bodyText}</p>

        <div className="mt-4 flex items-center gap-4">
          <SecondaryButton buttonText="Cancel" onClick={() => setOpen(false)} />
          <button
            onClick={handleModalDelete}
            className={` ${
              isLoading
                ? 'flex items-center justify-center relative h-12 px-4 py-6'
                : ''
            } text-white text-sm font-medium bg-danger py-4 px-[81px] rounded`}
          >
            {isLoading ? (
              <PuffLoader css={override} size={35} color="#ffffff" />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(DeleteModal);
