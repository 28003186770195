/* eslint-disable no-duplicate-case */

const getStatusTextAndStyles = status => {
  const normalizedStatus = (status || '').toLowerCase();
  switch (normalizedStatus) {
    case 'allow':
    case 'deposit':
    case 'successful':
    case 'completed':
    case 'connected':
      return {
        styles: 'bg-success20 text-success',
        text: 'Successful',
        solid: 'text-white bg-success',
      };
    case 'pending':
      return {
        styles: 'bg-[#FFD9512B] text-[#E4B303]',
        text: 'Pending',
        solid: 'text-white bg-warning',
      };

    case 'expired':
      return {
        styles: 'bg-[#6507082B] text-[#650708]',
        text: 'Expired',
        solid: 'bg-[#FFD9512B] text-[#E4B303]',
      };

    case 'transfer':
    case 'failed':
    case 'block':
    case 'not connected':
      return {
        styles: 'bg-[#A13E3F2B] text-status-404',
        text: 'Failed',
        solid: 'text-white bg-danger',
      };

    case 'started':
      return {
        styles: 'bg-batchBlue20 text-batchBlue',
        solid: 'bg-batchBlue20 text-batchBlue',
        text: 'Created',
      };

    case 'abandoned':
      return {
        styles: 'bg-disabled',
        solid: 'bg-disabled',
        text: 'Abandoned',
      };

    case 'ongoing':
      return {
        styles: 'bg-[#FF681C2B] text-[#FF681C]',
        solid: 'bg-[#FF681C2B] text-[#FF681C]',
        text: 'Ongoing',
      };

    default:
      return {
        styles: 'bg-disabled',
        solid: 'bg-disabled',
        text: 'Unknown',
      };
  }
};

const getStatusWithNumbers = number =>
  ({
    1: 'pending',
    2: 'successful',
    3: 'failed',
  }[number] || 'pending');

export {getStatusTextAndStyles, getStatusWithNumbers};
