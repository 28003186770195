import React, {useState} from 'react';
import {
  reconciliationAuthBg,
  reconciliationLogo,
} from '../../../../assets/images/images';
import {PrimaryButton} from '../../../../components';
import {Constants} from '../../../../helpers';
import {Link, useNavigate} from 'react-router-dom';
import {useMutation} from 'react-query';
import {forgotPassword} from '../../../../requests';
import {toast} from 'react-toastify';
import * as EmailValidator from 'email-validator';

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const {isLoading, mutateAsync} = useMutation(data => forgotPassword(data), {
    onSuccess: data => {
      toast.success(data.message);
      setEmail('');
    },
    onError: error => {
      toast.error(error.response.data.error);
    },
  });

  const handleSubmit = async e => {
    e.preventDefault();
    if (!email) {
      toast.error('Email is required');
    } else if (!EmailValidator.validate(email)) {
      toast.error('Email is not valid');
    } else {
      await mutateAsync({email});
    }
  };

  return (
    <div
      className="w-full h-full bg-cover bg-center bg-no-repeat min-h-screen"
      style={{backgroundImage: `url(${reconciliationAuthBg})`}}
    >
      <div className="py-[70px] px-5 lg:px-0 flex flex-col items-center">
        <img
          src={reconciliationLogo}
          alt="Dojah logo"
          className="w-[192px] h-[37px] mb-6"
        />
        <div className="bg-white border m-auto border-batchBlue20 py-10 px-8 rounded-2xl w-full md:w-[536px] shadow-reconcilable">
          <div className="">
            <h2 className="text-deepBlue font-semibold text-md mb-[6px]">
              Reset your password
            </h2>
            <p className="text-sm text-body">
              Provide the email attached to your account
            </p>

            <form className="mt-6">
              <label htmlFor="email" className="block mt-4">
                Email address
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={e => setEmail(e.target.value)}
                  className="mt-2"
                />
              </label>

              <PrimaryButton
                buttonText="Send a reset email"
                className="w-full mt-6"
                onClick={handleSubmit}
                loading={isLoading}
                disabled={isLoading || !email}
              />
            </form>

            <p
              onClick={() => navigate(Constants.PAGES_URL.SIGNIN)}
              className="mt-4 h-12 cursor-pointer font-medium w-full flex items-center justify-center gap-2 p-4 border border-body rounded text-center"
            >
              <span className="text-sm text-grey font-inter font-medium">
                Return to log in
              </span>
            </p>
          </div>
        </div>

        <div className="mt-8 flex items-center justify-center rounded-[30px] px-7 py-4 bg-white">
          <p className="text-sm font-medium text-body">
            Dont have an account?
            <Link
              to={Constants.PAGES_URL.RECONCILIATION_SIGNUP}
              className="text-brandBlue font-semibold ml-1"
            >
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
