import React, {useState, useEffect, useContext} from 'react';
import {Modal, DropdownIndicator} from '../../../components';
import {
  questionTitle,
  questionChoice,
  dropdownSm,
  deleteQuestion,
} from '../../../assets/images/images';
import Select from 'react-select';
import {EasyOnboardContext} from '../../../helpers/context/EasyOnboardContext';

const QuestionsModal = ({open, onClose}) => {
  const [questionType, setQuestionType] = useState('text');
  const [question, setQuestion] = useState('');
  const {
    title,
    setTitle,
    editingQuestion,
    currentQuestions,
    setCurrentQuestions,
  } = useContext(EasyOnboardContext);
  const [answerOptions, setAnswerOptions] = useState([]);
  const [openOptions, setOpenOptions] = useState(
    answerOptions?.map(() => true),
  );

  useEffect(() => {
    if (editingQuestion) {
      setQuestionType(editingQuestion.type);
      setQuestion(editingQuestion.text);
      setAnswerOptions(editingQuestion?.options);
      setOpenOptions(editingQuestion?.options?.map(() => true));
    } else {
      setQuestionType('text');
      setQuestion('');
      setAnswerOptions([]);
      setOpenOptions([]);
    }
  }, [editingQuestion]);

  useEffect(() => {
    if (!open) {
      setQuestionType('text');
      setQuestion('');
      setAnswerOptions([]);
      setOpenOptions([]);
    }
  }, [open]);

  const handleQuestionChange = e => {
    setQuestion(e.target.value);
    setCurrentQuestions({
      ...currentQuestions,
      text: e.target.value,
    });
  };

  const handleAddAnswerOption = () => {
    Array.isArray(answerOptions) && setAnswerOptions([...answerOptions, '']);
    setCurrentQuestions({
      ...currentQuestions,
      options: answerOptions,
    });
    Array.isArray(openOptions) && setOpenOptions([...openOptions, true]);
  };

  const handleAnswerOptionChange = (
    index,
    value,
    isDropdownClicked = false,
  ) => {
    const updatedOptions = [...answerOptions];
    updatedOptions[index] = value;
    setAnswerOptions(updatedOptions);
    setCurrentQuestions({
      ...currentQuestions,
      options: updatedOptions,
    });
    if (isDropdownClicked) {
      setOpenOptions(prevOpenOptions => {
        const newOpenOptions = [...prevOpenOptions];
        newOpenOptions[index] = !newOpenOptions[index];
        return newOpenOptions;
      });
    }
  };

  const handleRemoveAnswerOption = index => {
    const updatedOptions = [...answerOptions];
    updatedOptions.splice(index, 1);
    setAnswerOptions(updatedOptions);
    setCurrentQuestions({
      ...currentQuestions,
      options: updatedOptions,
    });
  };

  const questionTypeOptions = [
    {value: 'text', label: 'Open Question', image: questionChoice},
    {value: 'multiple', label: 'Multiple Choice', image: questionChoice},
    {value: 'single', label: 'Single Choice', image: questionChoice},
  ];

  const customStyles = {
    option: (base, {isFocused, isSelected}) => ({
      ...base,
      backgroundColor: isSelected ? '#deebff' : isFocused ? '' : '',
      borderRadius: '8px',
    }),
  };

  return (
    <Modal
      show={open}
      onClose={() => {
        // handleSubmit();
        onClose();
      }}
      extraModalClasses="max-w-[339px] overflow-y-auto h-auto  sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Configuration"
    >
      <div className="mt-4">
        <label>
          <span className="text-sm flex items-center gap-2 required">
            <img src={questionTitle} alt="" />
            Title
          </span>
          <input
            type="text"
            id="title"
            value={title}
            className="mt-2 font-normal h-12 rounded-xl"
            onChange={e => setTitle(e.target.value)}
            placeholder="Enter Title..."
          />
        </label>

        <label className="text-sm  gap-2 mt-6">Select Question Type</label>
        <div className="mt-1">
          <Select
            components={{DropdownIndicator}}
            className="custom-questions-select"
            isSearchable={false}
            styles={customStyles}
            value={questionTypeOptions.find(
              option => option.value === questionType,
            )}
            onChange={option => {
              setCurrentQuestions({
                ...currentQuestions,
                type: option.value,
              });
              setQuestionType(option.value);
            }}
            options={questionTypeOptions}
            formatOptionLabel={questionTypeOption => (
              <div className="flex items-center gap-2 h-6">
                <img src={questionTypeOption.image} alt="country-image" />
                <span className="text-tiny font-medium text-black h-4">
                  {questionTypeOption.label}
                </span>
              </div>
            )}
          />
        </div>
        <div className="h-[0.5px] w-full bg-[#FFF0F0] my-4" />
        <label htmlFor="question">
          <span className="text-sm flex items-center gap-2 mb-4">Question</span>
          <textarea
            id="question"
            value={question}
            onChange={handleQuestionChange}
            className="h-[154px] font-normal rounded-xl"
            placeholder="Enter question"
          />
        </label>
      </div>
      {(questionType === 'multiple' || questionType === 'single') && (
        <div className="mt-4 text-left">
          {answerOptions > 0 && <p className="font-medium text-body">Answer</p>}
          {answerOptions?.map((option, index) => (
            <div
              key={index}
              className={`flex-col items-start p-4 gap-2 mt-4 rounded-2xl bg-[#F6F6FB] ${
                openOptions[index] ? 'flex' : 'block'
              }`}
            >
              <div
                onClick={() => handleAnswerOptionChange(index, option, true)}
                className="flex items-center justify-between w-full cursor-pointer"
              >
                <p className="font-medium text-tiny text-grey-400">
                  Answer {index + 1}
                </p>
                <img
                  src={dropdownSm}
                  alt="dropdown"
                  className={`w-5 h-5 transition-transform ${
                    openOptions[index] ? 'rotate-180' : ''
                  }`}
                />
              </div>
              <div
                className={`w-full answer-option-content ${
                  openOptions[index] ? 'open contents' : 'hidden'
                }`}
              >
                <textarea
                  value={option}
                  onChange={e =>
                    handleAnswerOptionChange(index, e.target.value)
                  }
                  className="h-[154px] font-normal rounded-xl"
                  placeholder="Enter answer"
                />
                <div className="flex items-center justify-end w-full mt-2">
                  <button onClick={() => handleRemoveAnswerOption(index)}>
                    <img
                      src={deleteQuestion}
                      alt="delete"
                      className="w-4 h-4"
                    />
                  </button>
                </div>
              </div>
            </div>
          ))}
          <button
            className="mt-6 border-[#dce2e9] border w-full h-10 rounded-lg font-medium text-tiny flex items-center justify-center text-dojahBlue-900"
            onClick={handleAddAnswerOption}
          >
            Add new answer
          </button>
        </div>
      )}
    </Modal>
  );
};
export default QuestionsModal;
