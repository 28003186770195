import React from 'react';
import {CopyIcon} from './images';
import {toast} from 'react-toastify';

export const CopyItem = ({value}) => {
  const copyText = text => {
    navigator.clipboard.writeText(text);
    toast.success(`${text} copied`);
  };
  return (
    <span onClick={() => copyText(value)} className="ml-2 cursor-pointer">
      <CopyIcon />
    </span>
  );
};
