/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PrimaryButton} from '../../../components';
import QuestionsModal from '../SettingsModals/QuestionsModal';
import {
  editQuestion,
  trashQuestion,
  removeOption,
  addQuestion,
  infoRedSm,
} from '../../../assets/images/images';
import {EasyOnboardContext} from '../../../helpers/context/EasyOnboardContext';
import * as Actions from '../../../store/actions';

export default function Questions({appLogo}) {
  const {widgetPages, widgetScreen, singleApp, w_loading} = useSelector(
    state => state?.widgets,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    title,
    setTitle,
    questions,
    setQuestions,
    currentQuestions,
    setEditingQuestion,
    setCurrentQuestions,
    initialQuestionData,
  } = useContext(EasyOnboardContext);
  const dispatch = useDispatch();
  const minimumMultipleOptions = 3;
  const foundWidget = useMemo(
    () =>
      widgetPages &&
      widgetPages?.find(
        data =>
          data?.pageId === widgetScreen?.pageId &&
          data?.page === 'custom-questions',
      ),
    [widgetPages, widgetScreen],
  );

  const ALL_PAGES = JSON.parse(localStorage.getItem('pages'));

  const updateConfig = newConfig => {
    const newPages = ALL_PAGES?.map(page => {
      if (page?.page === foundWidget?.page) {
        return {...page, config: newConfig};
      }
      return page;
    });
    const updatedQuestions = newConfig?.questions?.map(q => ({
      text: q?.text,
      type: q?.type,
      ...(q.options && {options: q?.options}),
    }));

    dispatch(
      Actions?.setWidgetPages({
        ...foundWidget,
        config: {questions: updatedQuestions, title: newConfig?.title || ''},
      }),
    );
    localStorage.setItem('pages', JSON.stringify(newPages));
  };

  useEffect(() => {
    if (!foundWidget) return;

    const config = foundWidget?.config || {};
    const hasConfig = Object.values(config).length > 0;
    const questionsToSet = hasConfig
      ? config.questions.map((q, index) => ({...q, id: index + 1}))
      : [...questions];
    const titleToSet = title || config.title;
    setQuestions(questionsToSet);
    setTitle(titleToSet);
    updateConfig({
      questions: questionsToSet,
      title: titleToSet,
    });
  }, [
    widgetScreen,
    Object.keys(foundWidget || {})?.length > 0,
    setQuestions,
    setTitle,
    w_loading,
  ]);

  const handleOpenModal = () => {
    const newQuestion = {
      ...initialQuestionData,
      id: questions?.length + 1,
    };
    setCurrentQuestions({...newQuestion});
    setQuestions([...questions, newQuestion]);
    setTitle(title || foundWidget?.config?.title);
    setIsModalOpen(true);
    setEditingQuestion(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingQuestion(null);
  };

  const handleEditQuestion = question => {
    setEditingQuestion(question);
    setCurrentQuestions(question);
    setTitle(title || foundWidget?.config?.title);
    setIsModalOpen(true);
  };

  const handleDeleteQuestion = useCallback(
    questionToDelete => {
      const updatedQuestions = questions.filter(
        question => question !== questionToDelete,
      );
      setQuestions(updatedQuestions);
      updateConfig({
        questions: [...updatedQuestions],
        title: title || foundWidget?.config?.title,
      });
    },
    [questions],
  );

  const handleRemoveAnswerOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    const question = updatedQuestions[questionIndex];
    const updatedOptions = [...question.options];
    updatedOptions.splice(optionIndex, 1);
    // If there are no options left, remove the question
    // if (updatedOptions.length === 0) {
    //   updatedQuestions.splice(questionIndex, 1);
    //   setQuestions(updatedQuestions);
    //   return;
    // }
    updatedQuestions[questionIndex] = {...question, options: updatedOptions};
    setQuestions(updatedQuestions);
    updateConfig({
      questions: [...updatedQuestions],
      title: title || foundWidget?.config?.title,
    });
  };

  useEffect(() => {
    if (questions?.length > 0 && currentQuestions?.text === '') {
      const updatedQuestions = questions?.map(q => {
        if (q?.id === currentQuestions?.id) {
          return {...q, ...currentQuestions};
        }
        return q;
      });
      setTitle(title || foundWidget?.config?.title);
      setQuestions(updatedQuestions);
      updateConfig({
        questions: [...updatedQuestions],
        title: title || foundWidget?.config?.title,
      });
    } else if (currentQuestions?.text !== '') {
      const updatedQuestions = questions?.map(q =>
        q?.id === currentQuestions?.id ? currentQuestions : q,
      );
      // setTitle(title || foundWidget?.config?.title);
      setQuestions(updatedQuestions);
      updateConfig({
        questions: [...updatedQuestions],
        title: title || foundWidget?.config?.title,
      });
    }
  }, [currentQuestions, setCurrentQuestions, setQuestions, setTitle, title]);

  return (
    <div className="w-full font-inter">
      {questions?.length > 0 && (
        <div
          className={`mb-3 bg-white border border-[#F9F9F9] rounded-lg p-6  py-10  ${
            questions?.length > 4 ? 'h-[1000px] overflow-auto' : ''
          } `}
        >
          <div className="flex w-full items-center justify-center flex-col mt-2">
            <img
              src={appLogo || singleApp?.logo}
              alt="Logo"
              className="max-h-10"
            />
            <p className="text-black text-tiny bg-white w-fit px-3 rounded font-medium mt-6 max-w-[98%] break-all">
              {title}
            </p>
          </div>

          {questions?.map((question, index) => (
            <div key={index} className="mt-10">
              <p className="text-black text-tiny font-medium  max-w-[98%] break-all">
                {`${index + 1}. ${question?.text}`}
              </p>
              {['multiple', 'single'].includes(question?.type) &&
                question?.options?.length < minimumMultipleOptions && (
                  <div className="flex gap-2 items-center justify-start p-1 pl-3 mb-2 bg-statusBg-404 mt-1 rounded ">
                    <img src={infoRedSm} alt="" width={12} height={12} />
                    <p className="text-[11px] text-start text-black  leading-normal">
                      Options should NOT be less than three (
                      {minimumMultipleOptions}).
                    </p>
                  </div>
                )}
              {question?.type === 'text' && (
                <input
                  className="font-normal h-12 mt-3 rounded-xl"
                  placeholder="Enter answer"
                />
              )}
              {question?.type === 'multiple' && (
                <div className="flex flex-col mt-3 gap-4">
                  {Array.isArray(question?.options) &&
                    question?.options?.map((option, optionIndex) => (
                      <div
                        key={optionIndex}
                        className="flex items-center justify-between w-full rounded-lg px-4 py-3 bg-[#F6F6FB]"
                      >
                        <div className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            id={`option-${optionIndex}`}
                            name={`question-${index}`}
                            className="w-4 h-4"
                          />
                          <label
                            className="text-deepBlue text-tiny font-medium max-w-[100%] break-all"
                            htmlFor={`option-${optionIndex}`}
                          >
                            {option}
                          </label>
                        </div>
                        <button
                          className="min-w-[20px] min-h-[20px]"
                          onClick={() =>
                            handleRemoveAnswerOption(index, optionIndex)
                          }
                        >
                          <img src={removeOption} alt="remove" />
                        </button>
                      </div>
                    ))}
                </div>
              )}
              {question.type === 'single' && (
                <div className="flex flex-col mt-4 gap-4">
                  {Array.isArray(question?.options) &&
                    question?.options?.map((option, optionIndex) => (
                      <div
                        key={optionIndex}
                        className="flex items-center justify-between w-full rounded-lg px-4 py-3 bg-[#F6F6FB]"
                      >
                        <div className="flex items-center gap-2">
                          <input
                            type="radio"
                            id={`option-${optionIndex}`}
                            name={`question-${index}`}
                            className="w-4 h-4"
                          />
                          <label
                            className="text-deepBlue text-tiny font-medium max-w-[90%] break-all"
                            htmlFor={`option-${optionIndex}`}
                          >
                            {option}
                          </label>
                        </div>
                        <button
                          className="min-w-[20px] min-h-[20px]"
                          onClick={() =>
                            handleRemoveAnswerOption(index, optionIndex)
                          }
                        >
                          <img src={removeOption} alt="remove" />
                        </button>
                      </div>
                    ))}
                </div>
              )}
              <div className="mt-6 flex items-center gap-2 ">
                <PrimaryButton
                  className="font-medium text-xs text-white rounded-lg !p-2"
                  onClick={() => handleEditQuestion(question)}
                  buttonText={
                    <div className="flex items-center justify-center gap-2 font-inter">
                      <p>Edit Question</p>
                      <img src={editQuestion} alt="edit" />
                    </div>
                  }
                />

                <button
                  className="flex items-center font-inter font-medium border border-[#dbe1e9] text-error text-xs gap-2 h-7 rounded-lg p-2"
                  onClick={() => handleDeleteQuestion(question)}
                >
                  <span>Delete</span>
                  <img src={trashQuestion} alt="trash" />
                </button>
              </div>
              {index !== questions?.length - 1 && (
                <hr className="my-6 border-[#12376940]" />
              )}
            </div>
          ))}
        </div>
      )}
      <PrimaryButton
        buttonText={
          <div className="flex items-center justify-center gap-2 inter-font">
            <p>Add New Question</p>
            <img
              src={addQuestion}
              alt="add-question"
              className="min-w-[20px] min-h-[20px]"
            />
          </div>
        }
        className="w-full !py-4"
        onClick={handleOpenModal}
      />
      <QuestionsModal open={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
}
