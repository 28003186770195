import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {
  fetchFlaggedOverview,
  fetchOverview,
  fetchResolvedCasesOverview,
} from '../../../../requests/queries/flows';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

export const useFlowOverview = ({enabled}) => {
  const {slug} = useParams();
  const {
    auth: {userDetails},
  } = useSelector(state => state);

  const params = useMemo(
    () => ({
      project_id: slug || '',
      company_id: userDetails?.company?.id,
    }),
    [slug, userDetails?.company?.id],
  );

  const {data, isLoading} = useQuery(
    ['overview', {params}],
    () => fetchOverview({params}),
    {
      enabled,
    },
  );
  const {data: flaggedOverview, isLoading: flaggedLoading} = useQuery(
    ['flagged-overview', {params}],
    () => fetchFlaggedOverview({params}),
    {
      enabled,
    },
  );
  const {data: resolvedCasesOverview, isLoading: resolvedCasesLoading} =
    useQuery(
      ['resolved-overview', {params}],
      () => fetchResolvedCasesOverview({params}),
      {
        enabled,
      },
    );

  const overviewData = useMemo(
    () => (!isLoading && data && data?.entity) || [],
    [data, isLoading],
  );
  const flaggedOverviewData = useMemo(
    () => (!flaggedLoading && flaggedOverview && flaggedOverview?.entity) || [],
    [flaggedOverview, flaggedLoading],
  );

  const resolvedCasesData = useMemo(
    () =>
      (!resolvedCasesLoading &&
        resolvedCasesOverview &&
        resolvedCasesOverview?.entity) ||
      [],
    [resolvedCasesOverview, resolvedCasesLoading],
  );

  const calculateTotals = data => {
    if (!Array.isArray(data)) return;
    return data?.reduce(
      (acc, item) => {
        acc.amount += item.amount || 0;
        acc.count += +item.count || 0;
        return acc;
      },
      {amount: 0, count: 0},
    );
  };

  const TOTAL_EVENTS = useMemo(
    () => calculateTotals(overviewData),
    [overviewData],
  );

  const TOTAL_ALLOWED = useMemo(
    () =>
      calculateTotals(
        overviewData?.filter(data => data?.final_outcome === 'ALLOW'),
      ),
    [overviewData],
  );
  const TOTAL_RESOLVED_ALLOWED = useMemo(
    () =>
      calculateTotals(
        resolvedCasesData?.filter(data => data?.final_outcome === 'ALLOW'),
      ),
    [resolvedCasesData],
  );

  const TOTAL_PENDING = useMemo(
    () =>
      calculateTotals(
        overviewData?.filter(data => data?.final_outcome === 'PENDING'),
      ),
    [overviewData],
  );
  const TOTAL_BLOCKED = useMemo(
    () =>
      calculateTotals(
        overviewData?.filter(data => data?.final_outcome === 'BLOCK'),
      ),
    [overviewData],
  );
  const TOTAL_RESOLVED_BLOCKED = useMemo(
    () =>
      calculateTotals(
        resolvedCasesData?.filter(data => data?.final_outcome === 'BLOCK'),
      ),
    [resolvedCasesData],
  );

  const TOTAL_RESOLVED = useMemo(
    () =>
      calculateTotals(
        resolvedCasesData?.filter(data =>
          ['ALLOW', 'BLOCK']?.includes(data?.final_outcome),
        ),
      ),
    [resolvedCasesData],
  );

  const TOTAL_FLAGGED = useMemo(
    () =>
      calculateTotals(
        flaggedOverviewData?.filter(data =>
          ['PENDING', 'BLOCK']?.includes(data?.outcome),
        ),
      ),
    [flaggedOverviewData],
  );

  const TOTAL_NON_FLAGGED = useMemo(
    () => TOTAL_EVENTS?.count - TOTAL_FLAGGED?.count,
    [TOTAL_EVENTS, TOTAL_FLAGGED],
  );

  return {
    TOTAL_NON_FLAGGED,
    TOTAL_RESOLVED_ALLOWED,
    TOTAL_RESOLVED_BLOCKED,
    TOTAL_EVENTS,
    TOTAL_FLAGGED,
    TOTAL_RESOLVED,
    TOTAL_BLOCKED,
    TOTAL_ALLOWED,
    TOTAL_PENDING,
    isLoading,
  };
};
