import React, {useMemo, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import {connect} from 'react-redux';
import {useQuery} from 'react-query';
import moment from 'moment';
import {toast} from 'react-toastify';
import * as Actions from '../../store/actions';
import {
  DashboardLayout,
  PrimaryButton,
  TableLayout,
  Pagination,
  FilterButton,
  PageLimit,
  Filter,
  Loader,
  EmptyState,
  SearchInput,
} from '../../components';
import {
  corruptedFile,
  customer360Ic,
  eye,
  lookupEmpty,
} from '../../assets/images/images';
import {
  filterOutEmptyValues,
  getAppName,
  getStatusColor,
  mapToOptionsArray,
  parseUrlParams,
} from '../../helpers';
import {PAGES_URL} from '../../helpers/constants';
import PerformLookupModal from './PerformLookupModal';
import {fetchCustomerPool} from '../../requests/queries/customer360';
import {useAllApps} from '../../hooks/useApps';

const defaultFilterOptions = [
  // {
  //   radioOption: true,
  //   title: 'Status',
  //   name: 'status',
  //   values: [
  //     {
  //       label: 'Valid',
  //       value: 'matched',
  //       name: 'matched',
  //     },
  //     {
  //       label: 'Invalid',
  //       value: 'mismatched',
  //       name: 'mismatched',
  //     },
  //   ],
  // },
];

const tableHeader = [
  {
    label: 'Name',
    key: 'first_name',
  },
  {
    label: 'Date of birth',
    key: 'date_of_birth',
  },
  {
    label: 'Gender',
    key: 'gender',
  },
  {
    label: 'App name',
    key: 'app_name',
  },
  {
    label: 'countries',
    key: 'countries',
  },
  {
    label: 'status',
    key: 'status',
  },
  {
    label: 'date created',
    key: 'createdAt',
  },
];

const initialFilters = {
  start: '',
  end: '',
  status: '',
  search: '',
};

function Customer360({changeLookupPage}) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const {userApps} = useAllApps();

  const allApps = userApps?.map(app => app.value)?.join(',');

  const filteredApps = sessionStorage.getItem('filtered_apps');

  const {data: customerPool, isLoading} = useQuery(
    [
      'customer-pool',
      {page: currentPage, limit, allApps, filteredApps, ...filters},
    ],
    () =>
      fetchCustomerPool(filteredApps ?? allApps, {
        page: currentPage,
        limit,
        ...filters,
      }),
    {
      onSuccess: data => {
        toast.success(data.message);
        setFilters({
          ...filters,
          download: undefined,
        });
      },
      onError: error => {
        toast.error(error.response.data.error);
      },
      enabled: !!allApps,
      retry: 1,
    },
  );

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const customerPoolData = customerPool?.rows;

  const getStatus = status =>
    typeof status === 'object' && Object.values(status).every(e => e)
      ? {
          text: 'Valid',
          styles: getStatusColor('verified'),
        }
      : {
          text: 'Invalid',
          styles: getStatusColor('not verified'),
        };

  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(pageParam);
    }

    const statusParam = searchParams.get('status');
    const searchParam = searchParams.get('search');
    if (statusParam) {
      apiParams.status = statusParam;
    }

    if (searchParam) {
      apiParams.search = searchParam;
    }

    if (
      !apiParams.start &&
      !apiParams.end &&
      (!apiParams.search || apiParams.search === '') &&
      (!apiParams.status || apiParams.status === '')
    ) {
      setFiltersApplied(false);
    } else {
      setFiltersApplied(true);
    }

    if (Object.keys(apiParams).length > 1) {
      setFilters(apiParams);
    }

    window.addEventListener('beforeunload', () => {
      sessionStorage.removeItem('filtered_apps');
    });

    return () => {
      sessionStorage.removeItem('filtered_apps');
    };
  }, [limit]);

  const filterOptions = useMemo(() => {
    if (!userApps) {
      return defaultFilterOptions;
    }

    return [
      ...defaultFilterOptions,
      {
        title: 'Apps',
        name: 'apps',
        values: mapToOptionsArray(userApps),
      },
    ];
  }, [userApps]);

  const handleFilter = (selectedOptions, shouldDownload = false) => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);

    const {status} = filteredOptions;

    if (Array.isArray(status)) {
      filteredOptions.status = status.join(',');
    }

    if (filteredOptions.apps) {
      const appIds = filteredOptions.apps.map(app => app).join(',');
      sessionStorage.setItem('filtered_apps', appIds);
    }

    setCurrentPage(1);

    if (shouldDownload) {
      filteredOptions.download = true;
      setFilters({
        ...filters,
        ...filteredOptions,
      });
    }

    const {apps, ...rest} = filteredOptions;
    setFilters(rest);
    setFiltersApplied(true);
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);
    setFilters(initialFilters);
    setFiltersApplied(false);
    setOpenFilter(true);
    sessionStorage.removeItem('filtered_apps');
  };

  const isFiltered =
    customerPoolData?.length === 0 && !isLoading && filtersApplied;

  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={customer360Ic} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Customer 360</p>
        </div>
      }
    >
      <PerformLookupModal open={open} setOpen={setOpen} />
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={filterOptions}
      />

      <>
        {!isLoading && customerPoolData?.length === 0 && !filtersApplied ? (
          <EmptyState
            body="Perform multiple identity lookup with ease."
            src={lookupEmpty}
            bodyMaxWidth="max-w-[203px]"
            noBtn
            customBtn={
              <PrimaryButton
                buttonText="Add Customer"
                className="mt-2 w-fit"
                onClick={() => {
                  changeLookupPage(1);
                  setOpen(!open);
                }}
              />
            }
          />
        ) : (
          <>
            <div className="flex pt-5 flex-col items-start justify-between gap-6 mt-4 mb-6 md:flex-row md:items-center md:gap-0 sm:mt-0">
              <h2 className="text-base font-medium text-grey sm:text-lg">
                Customer 360
              </h2>

              <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
                <SearchInput
                  onChange={value => {
                    const searchParams = new URLSearchParams(
                      document.location.search,
                    );

                    if (value === '') {
                      searchParams.delete('search');
                    } else {
                      searchParams.set('search', value);
                      setFiltersApplied(true);
                    }

                    const newSearchParams = searchParams.toString();
                    navigate(`?${newSearchParams}`);
                    setFilters({...filters, search: value});
                  }}
                  className="outline-white80"
                />

                <FilterButton
                  openFilter={openFilter}
                  setOpenFilter={setOpenFilter}
                />

                <PrimaryButton
                  buttonText="Add Customer"
                  onClick={() => {
                    changeLookupPage(1);
                    setOpen(!open);
                  }}
                />

                <div
                  className="fixed bottom-0 left-0 right-0 z-10 px-4 py-2 overflow-x-hidden sm:hidden bg-white80"
                  style={{
                    boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)',
                  }}
                >
                  <PrimaryButton
                    buttonText="Perform Lookup"
                    xPadding="p-[35px]"
                    onClick={() => setOpen(!open)}
                    className="w-full bottom-2"
                  />
                </div>
              </div>
            </div>

            {isLoading ? (
              <Loader height={50} />
            ) : isFiltered ? (
              <EmptyState
                body={
                  'This filter did not return any data, try using different values.'
                }
                src={corruptedFile}
                noBtn
                customBtn={
                  <button
                    onClick={handleResetFilter}
                    className="text-brandBlue p-4 text-sm font-medium"
                  >
                    Update preferences
                  </button>
                }
              />
            ) : (
              <TableLayout negativeMargins>
                <thead className="text-xs font-semibold uppercase">
                  <tr className="bg-white80">
                    {tableHeader.map(({label, key}) => (
                      <th
                        key={key}
                        className={`${
                          label === 'Name'
                            ? 'p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap'
                            : 'p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap'
                        }`}
                      >
                        <div className="flex items-end gap-1 cursor-pointer">
                          <span>{label}</span>
                        </div>
                      </th>
                    ))}

                    <th></th>
                  </tr>
                </thead>

                <tbody className="text-sm">
                  {customerPoolData &&
                    customerPoolData?.map(verification => (
                      <tr
                        className="border-b border-grey60"
                        key={verification.id}
                      >
                        <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                          {verification?.first_name} {verification?.last_name}
                        </td>
                        <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                          {verification?.date_of_birth
                            ? moment(verification?.date_of_birth).format(
                                'MMM Do, YYYY',
                              )
                            : 'N/A'}
                        </td>
                        <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap ">
                          {verification?.gender || 'N/A'}
                        </td>
                        <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                          {getAppName(userApps, verification.app_id) || 'N/A'}
                        </td>
                        <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                          <div className="flex items-center -space-x-[6px]">
                            {verification?.countries &&
                              verification?.countries
                                ?.split(',')
                                ?.map((country, i) => (
                                  <div
                                    key={i}
                                    className="w-5 h-4 pt-1 flex items-center justify-center bg-blue-50 outline outline-1 outline-blue-100 rounded"
                                  >
                                    {getUnicodeFlagIcon(country?.toLowerCase())}
                                  </div>
                                ))}
                          </div>
                        </td>
                        <td className="p-4 pl-0 whitespace-nowrap">
                          <div
                            className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit ${
                              getStatus(verification?.integrity_check)?.styles
                            }`}
                          >
                            {getStatus(verification?.integrity_check)?.text}
                          </div>
                        </td>
                        <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                          {moment(verification?.createdAt).format(
                            'Do MMM, YYYY h:mm A',
                          )}
                        </td>
                        <td className="p-4 pl-0 whitespace-nowrap">
                          <div className="flex items-center gap-4">
                            <button
                              title="View"
                              onClick={() => {
                                navigate(
                                  PAGES_URL.CUSTOMER_360_GOVT_LOOKUP.replace(
                                    ':slug',
                                    verification.id,
                                  ),
                                );
                              }}
                              className="h-4 aspect-square"
                            >
                              <img src={eye} alt="" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </TableLayout>
            )}

            {customerPoolData && customerPoolData.length > 0 && (
              <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
                <div className="flex items-center text-body text-tiny">
                  <PageLimit
                    onLimitChange={setLimit}
                    total={customerPool && customerPool?.count}
                    length={customerPoolData?.length}
                    limit={limit}
                  />
                </div>

                <div className="mb-8 sm:mb-0">
                  <Pagination
                    total={
                      customerPoolData && Math.ceil(customerPool?.count / limit)
                    }
                    current={+currentPage}
                    onPageChange={activePage => {
                      pageClick(activePage);
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(Customer360);
