import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  chevronRight,
  configurationIc,
  copyGrey,
  eye,
  trash,
} from '../../assets/images/images';
import {
  DashboardLayout,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
} from '../../components';
import * as Actions from '../../store/actions';
import moment from 'moment/moment';
import {toast} from 'react-toastify';
import EmptyState from '../../components/EmptyState';
import DeleteModal from './DeleteModal';
import WebhookSubscribeModal from './WebhookSubscribeModal';
import {parseUrlParams} from '../../helpers';
import {PageContext} from '../../helpers/context';
import {useQuery} from 'react-query';
import {fetchWebhookSubscriptions} from '../../requests/queries/Developers/webbhook';
import {useAllApps} from '../../hooks/useApps';

function DevelopersWebhook({getApiLogsFilters, getPortalUrl}) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [params, setParams] = useState({});
  const [apiParams, setApiParams] = useState({});
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);

  const {userApps} = useAllApps();

  const appParams = useMemo(
    () => ({
      ...apiParams,
      app_id: userApps?.map(app => app.value),
    }),
    [apiParams, userApps],
  );

  const {data: webhooks, isLoading} = useQuery(
    ['webhook-sub', {params: {...appParams}}],
    () => fetchWebhookSubscriptions({params: {...appParams}}),
    {
      enabled: userApps?.length > 0,
    },
  );

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('webhooks');
  }, [setPageName]);

  const pageClick = selected => {
    setCurrentPage(selected);
  };
  const copyText = text => {
    navigator.clipboard.writeText(text);
    toast.success(`End point copied`);
  };

  const getAppName = useCallback(
    id => (userApps && userApps?.find(app => app?.value === id)?.name) || '',
    [userApps],
  );

  useEffect(() => {
    getApiLogsFilters();
  }, [getApiLogsFilters]);

  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(parseInt(pageParam));
      apiParams.page = parseInt(pageParam);
    } else {
      apiParams.page = currentPage;
    }

    setApiParams(apiParams);
  }, [currentPage, limit]);

  return (
    <DashboardLayout
      bg="bg-white"
      topPadding="pt-6"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={configurationIc} alt="" />
          <p className="ml-2 -mb-1">Developers</p>
          <img src={chevronRight} alt="" />
          <p className="-mb-1">Webhooks</p>
        </div>
      }
    >
      <DeleteModal
        bodyText={`Please confirm your decision to delete this webhook. Once deleted, it cannot be recovered.`}
        params={params}
        loading={isLoading}
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
      />
      <WebhookSubscribeModal
        open={showSubscribeModal}
        setOpen={setShowSubscribeModal}
      />
      {webhooks?.subscriptions.length === 0 && (
        <h2 className="text-grey text-base sm:text-lg font-medium">
          Webhooks Subscriptions
        </h2>
      )}

      {!isLoading &&
      (webhooks === null || webhooks?.subscriptions.length === 0) ? (
        <EmptyState
          body="No hook(s) found"
          height={75}
          buttonText="Subscribe"
          onClick={() => setShowSubscribeModal(true)}
        />
      ) : (
        <>
          <section className="flex flex-col">
            <div className="flex items-center justify-between w-full mb-6">
              <h2 className="text-grey text-base sm:text-lg font-medium">
                Webhooks
              </h2>
              <div className="flex items-center gap-4">
                <PrimaryButton
                  buttonText="Subscribe"
                  onClick={() => setShowSubscribeModal(true)}
                />
              </div>
            </div>

            <TableLayout
              negativeMargins
              negativeRightMargin="-mr-4 xl:-mr-[76px]"
            >
              <thead className="text-xs font-semibold uppercase">
                <tr className="bg-white80">
                  <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>app name</span>
                    </div>
                  </th>
                  <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>Environment</span>
                    </div>
                  </th>
                  <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>SERVICE TYPE</span>
                    </div>
                  </th>
                  <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>Date subscribed</span>
                    </div>
                  </th>
                  <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>end point</span>
                    </div>
                  </th>
                  <th className="min-w-[175px]"></th>
                </tr>
              </thead>
              {isLoading ? (
                <div className="w-full absolute">
                  <Loader />
                </div>
              ) : (
                <tbody className="text-sm bg-white">
                  {webhooks?.subscriptions &&
                    webhooks?.subscriptions.map(subscription => (
                      <tr
                        className="border-b border-grey60"
                        key={subscription?.entity_id}
                      >
                        <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                          {getAppName(subscription?.app_id) || '-'}
                        </td>
                        <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                          <div
                            className={`uppercase py-1 px-2 text-xs font-medium  rounded-full flex items-center w-fit ${
                              subscription?.environment === 'live'
                                ? 'text-status-200 bg-statusBg-200'
                                : 'text-status-500 bg-statusBg-500'
                            }`}
                          >
                            <span className="-mb-[2px]">
                              {subscription?.environment === 'live'
                                ? 'live mode'
                                : subscription?.environment || '-'}
                            </span>
                          </div>
                        </td>
                        <td className="p-4 pl-0 text-tiny text-body uppercase whitespace-nowrap">
                          {subscription?.service?.replaceAll('_', ' ') || '-'}
                        </td>
                        <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                          {moment(subscription?.date_created).format(
                            'Do MMM YYYY hh:mm',
                          )}
                        </td>
                        <td className="p-4 pl-0 text-tiny whitespace-nowrap text-brandBlue">
                          {`${subscription?.endpoint?.substring(0, 26)}...` ||
                            '-'}
                        </td>
                        <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                          <div className="flex items-center gap-4">
                            <button
                              onClick={() => copyText(subscription?.endpoint)}
                              className="flex items-center relative bottom-[3px] px-2 text-grey80 bg-white80 pt-[9px] pb-[7px] rounded"
                            >
                              <img
                                src={copyGrey}
                                alt=""
                                width={14}
                                height={14}
                              />
                              <span className="text-xs pl-1 font-medium relative top-[2px]">
                                Copy
                              </span>
                            </button>
                            <button
                              title="View"
                              className="h-4 cursor-pointer aspect-square"
                              onClick={() => {
                                getPortalUrl(
                                  subscription?.convoy_app_id,
                                  subscription?.convoy_subscription_id,
                                );
                                navigate(
                                  `/developers/webhooks/${subscription.convoy_app_id}`,
                                  {
                                    state: {
                                      appId: subscription.convoy_app_id,
                                    },
                                  },
                                );
                              }}
                            >
                              <img src={eye} alt="" />
                            </button>
                            <button
                              // disabled={!canManageApps}
                              title={'Delete app'}
                              onClick={() => {
                                setParams({
                                  app_id: subscription.app_id,
                                  service: subscription.service,
                                });
                                setShowDeleteModal(true);
                              }}
                              className="h-4 aspect-square"
                              // style={{cursor: !canManageApps && 'not-allowed'}}
                            >
                              <img src={trash} alt="" width={16} height={16} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </TableLayout>

            {webhooks && webhooks?.subscriptions?.length !== 0 && (
              <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
                <div className="flex items-center text-body text-tiny">
                  <PageLimit
                    onLimitChange={setLimit}
                    // className="mt-3 mt-sm-0"
                    total={webhooks && webhooks?.totalRecords}
                    length={webhooks && webhooks?.subscriptions?.length}
                    limit={limit}
                  />
                </div>

                <div className="mb-8 sm:mb-0">
                  <Pagination
                    total={
                      webhooks && Math.ceil(webhooks?.totalRecords / limit)
                    }
                    current={+currentPage}
                    onPageChange={activePage => {
                      pageClick(activePage);
                      const searchParams = new URLSearchParams(
                        document.location.search,
                      );
                      searchParams.set('page', activePage);
                      const newSearchParams = searchParams.toString();
                      navigate(`?${newSearchParams}`);
                    }}
                  />
                </div>
              </div>
            )}
          </section>
        </>
      )}
    </DashboardLayout>
  );
}
export default connect(state => state, Actions)(DevelopersWebhook);
